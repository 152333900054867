import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { PlatformsStep } from "../../components/StepsContent/platforms";
import { postMethodAuthed } from "../../backend/services";
import {
	ChangeNdaRequirement,
	sendQuestionnarieAnswer,
} from "../../hooks/projects";

const initialState = {
	project: {},
	activeProjectUid: "", // "Active Project"
	hidden: false, //to hide/show the account button
	hiddenAccordions: false, //to hide/show the accordions in the sidebar
	requests: [], // all the requests of the Project
	tickets: [], // all the tickets of the Project
	quotes: [], // all the quotes of the Project
	contents: [], // all the contents of the Project
	isLoading: false,
	error: null,
	projects: [],
	newProjectFalback: false,
	platforms: [],
};

/**
 * Updates the selected project for a client in the Redux store and the database.
 * @param {Object} payload - The payload object containing the client UID and project UID.
 * @param {string} payload.clientUid - The UID of the client whose selected project is being updated.
 * @param {string} payload.uid - The UID of the project being selected.
 * @returns {Promise<Object>} A Promise that resolves with the updated project data.
 */
async function UpdateProject(project, token) {
	return await postMethodAuthed({
		route: "project/updateProject",
		body: { project },
		accessToken: token,
	})
		.then((res) => {
			if (res.success) return res.data;
			else throw new Error(res.message);
		})
		.catch((e) => {
			return e;
		});
}

/**
 * Updates a project in the Redux store and the database.
 * @param {Object} payload - The payload object containing the project data to update.
 * @returns {Promise<Object>} A Promise that resolves with the updated project data.
 */
export const updateProject = createAsyncThunk(
	"contents/updateProject",
	async (payload, { getState }) => {
		const state = getState();
		await UpdateProject(state.projectsRedux.project, state.auth.token);
		// todo[]: handle error
		return state.projectsRedux.project;
	}
);

/**
 * Updates the selected project for a client in the database.
 * @param {Object} project - The project object containing the client UID and project UID.
 * @param {string} project.clientUid - The UID of the client whose selected project is being updated.
 * @param {string} project.uid - The UID of the project being selected.
 * @returns {Promise<Object>} A Promise that resolves with the updated project data.
 */
async function UpdateSelectedProject(project, token) {
	return await postMethodAuthed({
		route: "project/updateSelectedProject",
		body: { clientUid: project.clientUid, projectUid: project.uid },
		accessToken: token,
	})
		.then((res) => {
			if (res.success) return res.data;
			else throw new Error(res.message);
		})
		.catch((e) => {
			return e;
		});
}

/**
 * Updates the selected project for a client in the Redux store and the database.
 * @param {Object} payload - The payload object containing the client UID and project UID.
 * @param {string} payload.clientUid - The UID of the client whose selected project is being updated.
 * @param {string} payload.uid - The UID of the project being selected.
 * @returns {Promise<Object>} A Promise that resolves with the updated project data.
 */
export const updateSelectedProject = createAsyncThunk(
	"contents/updateSelectedProject",
	async (payload, { getState }) => {
		const state = getState();
		await UpdateSelectedProject(payload, state.auth.token);
		// todo[]: handle error
		return payload;
	}
);

export const sendAnswer = createAsyncThunk(
	"project/sendAnswer",
	async (payload, { getState }) => {
		const state = getState();
		const { project } = state.projectsRedux;
		const { question, answer, id } = payload;
		const { uid } = project;
		const { token } = state.auth;
		await sendQuestionnarieAnswer(uid, question, answer, id, token);
		return payload;
	}
);

export const changeNdaRequirement = createAsyncThunk(
	"project/changeNdaRequirement",
	async (payload, { getState }) => {
		const state = getState();
		const { project } = state.projectsRedux;
		const { uid } = project;
		const { token } = state.auth;
		await ChangeNdaRequirement(uid, payload, token);
		return payload;
	}
);

export const projectSlice = createSlice({
	name: "Project",
	initialState,
	reducers: {
		setProject: (state, action) => {
			state.project = action.payload;
		},
		setProjects: (state, action) => {
			state.projects = action.payload;
		},
		setActiveProjectById: (state, action) => {
			state.activeProjectUid = action.payload;
			state.project = state.projects.find((p) => p.uid === action.payload);
		},
		setActiveProjectId: (state, action) => {
			state.activeProjectUid = action.payload;
		},
		setActiveStep: (state, action) => {
			state.project.activeStep = action.payload;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.activeStep = action.payload;
					return p;
				} else {
					return p;
				}
			});

			state.projects = projects;
		},
		handleComplete: (state, action) => {
			state.project.completedSteps[action.payload] = true;
			state.project.lastCompletedStep = action.payload;
			state.project.activeStep += 1;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.completedSteps[action.payload] = true;
					p.lastCompletedStep = action.payload;
					p.activeStep += 1;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		handleNext: (state) => {
			state.project.activeStep += 1;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.activeStep += 1;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		handleNextTest: (state) => {
			state.project.activeStep += 1;
			const projects = [...state.projects];
			projects.find((p) => p.uid === state.activeProjectUid).activeStep += 1;
			state.projects = projects;
		},
		handleBack: (state) => {
			state.project.activeStep -= 1;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.activeStep -= 1;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		restartProjects: (state) => {
			state = initialState;
			return state;
		},
		addRequests: (state, action) => {
			state.project.requests = action.payload;
		},
		changeNewProjectsFallback: (state, action) => {
			state.newProjectFalback = action.payload;
		},
		addPlatform: (state, action) => {
			const platforms = [...state.platforms];
			const platform = { ...action.payload };
			delete platform.developers;
			Object.assign(platform, {
				requested: true,
				sharedCredentials: false,
				credentials: {},
			});
			platforms.push(platform);
			state.platforms = platforms;
		},
		delPlatform: (state, action) => {
			const platforms = [...state.platforms];
			const platformsSubs = platforms?.filter(
				(platformFilter) => platformFilter.id !== action.payload.id
			);
			state.platforms = platformsSubs;
		},
		setRequests: (state, action) => {
			console.log(
				"🚀 ~ file: projectSlice.js:240 ~ action.payload:",
				action.payload
			);
			console.log(
				"🚀 ~ file: projectSlice.js:240 ~ ...action.payload:",
				...action.payload
			);
			state.project.requests = [...state.project.requests, ...action.payload];
		},
		setTickets: (state, action) => {
			const tickets = [...action.payload];
			tickets.forEach((ticket) => {
				delete ticket.createdAt;
			});
			state.project.tickets = tickets;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.tickets = tickets;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		setProjectLeader: (state, action) => {
			state.project.projectLeader = action.payload;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.projectLeader = action.payload;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		setMembers: (state, action) => {
			state.project.members = action.payload;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.members = action.payload;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		addQuote: (state, action) => {
			// ineed to create a copy of the object quote and tickets array
			const quote = { ...state.project.quote };
			const tickets = [...state.project.tickets];
			const quotes = [...state.project.quotes];

			const ticket = quotes.find((t) => t.uuid === action.payload.ticket.uuid);

			if (ticket) {
				return;
			}

			// then check if the ticket (action.payload) has the seeHowWeDidIt property
			if (action.payload.seeHowWeDidIt) {
				// if has, then add 30% to the price and set the seeHowWeDidIt to true
				const ticket = { ...action.payload.ticket };
				ticket.price *= 1.3;
				ticket.seeHowWeDidIt = true;
				ticket.selectedToPay = true;
				// then find the ticket in the tickets array and update the price and seeHowWeDidIt
				const editableTicket = tickets?.find(
					(ticketFilter) => ticketFilter.uuid === action.payload.ticket.uuid
				);

				// editableTicket.price = ticket.price;
				editableTicket.seeHowWeDidIt = true;
				editableTicket.selectedToPay = true;
				quote.estimatedTime += ticket.estimatedTime;
				quote.totalPrice += ticket.price;

				// then add the ticket to the quotes array then set the quote and tickets to the project
				state.tickets = tickets;
				state.project.quotes.push(ticket);
				state.project.quote = quote;
			} else {
				// if not, then just add the ticket to the quotes array then set the quote and tickets to the project
				const ticket = { ...action.payload.ticket };
				ticket.selectedToPay = true;
				const editableTicket = tickets?.find(
					(ticketFilter) => ticketFilter.uuid === ticket.uuid
				);
				editableTicket.selectedToPay = true;

				quote.estimatedTime += action.payload.ticket.estimatedTime;
				quote.totalPrice += action.payload.ticket.price;
				state.project.quotes.push(ticket);
				state.project.quote = quote;
			}
		},
		delQuote: (state, action) => {
			const quotes = [...state.project.quotes];
			const tickets = [...state.project.tickets];
			const quote = { ...state.project.quote };

			const quotesSubs = quotes?.filter(
				(quoteFilter) => quoteFilter.uuid !== action.payload.ticket.uuid
			);
			const findTicket = tickets?.find(
				(ticketFilter) => ticketFilter.uuid === action.payload.ticket.uuid
			);

			if (findTicket.seeHowWeDidIt) {
				quote.totalPrice -= findTicket.price * 1.3;
				quote.estimatedTime -= findTicket.estimatedTime;
				findTicket.seeHowWeDidIt = false;
				findTicket.selectedToPay = false;
				state.project.quotes = quotesSubs;
				state.project.tickets = tickets;
				state.project.quote = quote;
			} else {
				quote.totalPrice -= action.payload.ticket.price;
				quote.estimatedTime -= action.payload.ticket.estimatedTime;
				findTicket.selectedToPay = false;
				state.project.quote = quote;
				state.project.quotes = quotesSubs;
				state.project.tickets = tickets;
			}
		},
		resetQuotes: (state) => {
			state.project.quotes = [];
			state.project.quote = {
				estimatedTime: 0,
				totalPrice: 0,
			};
		},
		updatePlatform: (state, action) => {
			const platforms = [...state.platforms];
			const platform = { ...action.payload };
			const platformIndex = platforms.findIndex(
				(platformFilter) => platformFilter.uid === platform.uid
			);
			platforms[platformIndex].sharedCredentials = true;
			platforms[platformIndex] = platform;
			state.platforms = platforms;
		},
		updateProjectNameRedux: (state, action) => {
			state.project.projectName = action.payload;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.projectName = action.payload;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		updatePlatforms: (state, action) => {
			state.project.platforms = action.payload;
		},
		restartEverythingButProjectAndactiveProjectUid: (state) => {
			state.hidden = initialState.hidden;
			state.hiddenAccordions = initialState.hiddenAccordions;
			state.requests = initialState.requests;
			state.tickets = initialState.tickets;
			state.quotes = initialState.quotes;
			state.contents = initialState.contents;
			state.isLoading = initialState.isLoading;
			state.error = initialState.error;
			state.projects = initialState.projects;
			state.newProjectFalback = initialState.newProjectFalback;
			state.platforms = initialState.platforms;
		},
		exclusiveChatChange: (state, action) => {
			state.project.exclusiveChat = action.payload;
			const project = { ...state.project };
			const projects = [...state.projects];
			projects.map((p) => {
				if (p.uid === project.uid) {
					p.exclusiveChat = action.payload;
					return p;
				} else {
					return p;
				}
			});
			state.projects = projects;
		},
		// sumQuote deprecated
		// subQuote deprecated
	},
	extraReducers: (builder) => {
		builder.addCase(updateProject.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateProject.fulfilled, (state) => {
			state.isLoading = false;
		});
		builder.addCase(updateProject.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
		builder.addCase(updateSelectedProject.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateSelectedProject.fulfilled, (state, action) => {
			state.project = action.payload;
			state.activeProjectUid = action.payload.uid;
			state.isLoading = false;
		});
		builder.addCase(updateSelectedProject.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
		builder.addCase(sendAnswer.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(sendAnswer.fulfilled, (state, action) => {
			const stateProjects = { ...state.project };
			state.project.answers = [...stateProjects.answers, action.payload];
			state.isLoading = false;
		});
		builder.addCase(sendAnswer.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
		builder.addCase(changeNdaRequirement.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(changeNdaRequirement.fulfilled, (state, action) => {
			state.project.requireNda = action.payload;
			state.isLoading = false;
		});
		builder.addCase(changeNdaRequirement.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
	},
});

export const {
	setProject,
	setProjects,
	setActiveProjectById,
	setActiveProjectId,
	setActiveStep,
	setLastCompletedStep,
	restartProjects,
	handleComplete,
	handleNext,
	handleBack,
	handleNextTest, // to test updateProject
	addRequests,
	changeNewProjectsFallback,
	addPlatform,
	delPlatform,
	setRequests,
	setTickets,
	addQuote,
	delQuote,
	setProjectLeader,
	setMembers,
	resetQuotes,
	updatePlatform,
	updatePlatforms,
	updateProjectNameRedux,
	exclusiveChatChange,
} = projectSlice.actions;

export default projectSlice.reducer;
