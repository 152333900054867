import React from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import MuiLink from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "yup-phone";

import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { getByIdNoAuth, postMethodAuthed } from "../../backend/services";

import { MainPage } from "../templates/MainPage";
import { TermsModal } from "../templates/modals";
import CloseModal from "../../Sources/Icons/CloseModal.svg";
import { ChatContext } from "../../Context/ChatContext";
import Company from "../../Sources/Icons/Company.svg";
import Phone from "../../Sources/Icons/Phone.svg";
import Question from "../../Sources/Icons/Question.svg";

import { isMobile } from "../../hooks/utils";

import {
	handleComplete,
	setActiveStep,
	updateProject,
} from "../../features/project/projectSlice";
import { updateUser } from "../../features/auth/authSlice";

import "./signupGoogle.css";
import { BackdropContext } from "../../Context/BackdropContext";

export function SignUpGoogle() {
	const [termsAgreement, setTermsAgreement] = React.useState({ content: "" });
	const [privacyAgreement, setPrivacyAgreement] = React.useState({
		content: "",
	});

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	const [loadingAgreements, setLoadingAgreements] = React.useState(false);
	const [loadingPrivacy, setLoadingPrivacy] = React.useState(false);

	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState("paper");

	const [openPrivacy, setOpenPrivacy] = React.useState(false);

	const { dispatchChat } = React.useContext(ChatContext);
	const check = isMobile();

	function handleClickOpenPrivacy(scrollType) {
		setOpenPrivacy(true);
		setScroll(scrollType);
	}

	function handleClickOpen(scrollType) {
		setOpen(true);
		setScroll(scrollType);
	}
	const phoneShema = yup.string().phone("US", true);
	const maxWidth = "md";
	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);
	const dispatch = useDispatch();

	const validationSchema = yup.object({
		company: yup.string(""),
		phone: yup.string().test("test-phone", "Invalid phone number", (value) => {
			if (value) return phoneShema.isValidSync(value);
			return true;
		}),
		feedback: yup.string(""),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: "onSubmit",
	});

	async function getAgreements() {
		try {
			setLoadingAgreements(true);
			const tos = await getByIdNoAuth({
				route: "agreement/getAgreementType",
				id: "TERMS",
			});
			setTermsAgreement({ content: tos.document.content });
			setLoadingAgreements(false);
		} catch (error) {
			console.error(error);
		}
		try {
			setLoadingPrivacy(true);
			const PrivAgree = await getByIdNoAuth({
				route: "agreement/getAgreementType",
				id: "PRIVACY",
			});
			setPrivacyAgreement({ content: PrivAgree.document.content });
			setLoadingPrivacy(false);
		} catch (error) {
			console.error(error);
		}
		dispatchChat({ type: "HIDE_CHAT" });
	}
	// const { handleComplete } = useProjects();

	async function onSubmit(data) {
		changeLoadingBackdrop(true);
		const body = {
			phone: data.phone,
			feedback: data.feedback,
			company: data.company,
			uid: auth.user.uid,
		};

		try {
			const { success, client } = await postMethodAuthed({
				route: "client/saveAdditionalInfo",
				body,
				accessToken: auth.token,
			});

			if (!success) throw new Error("Error saving additional info");
			dispatch(updateUser(client));
			if (!projects.project.completedSteps[1]) {
				dispatch(handleComplete(1));
				if (!projects.project.requireNda) {
					dispatch(handleComplete(2));
				}
			} else {
				dispatch(setActiveStep(projects.project.requireNda ? 2 : 3));
			}
			dispatch(updateProject());
			changeLoadingBackdrop(false);
		} catch (error) {
			console.error(error);
			enqueueSnackbar("Error", { variant: "error" });
			changeLoadingBackdrop(false);
		}
	}

	React.useEffect(() => {
		getAgreements();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MainPage
			header={true}
			title="Sign up"
			show={false}
			subtitle={`Email: ${auth.user.email}`}
			width={"30%"}
			backFunction={0}
			backNavigate="/start"
		>
			<Stack
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid
						direction="row"
						justifyContent="center"
						alignItems="center"
						container
						spacing={2}
					>
						<Grid item xs={12} md={12}>
							<TextField
								{...register("company")}
								id="company"
								placeholder="Company name (optional)"
								variant="outlined"
								fullWidth
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Avatar variant="inputAdorment" src={Company} />
										</InputAdornment>
									),
								}}
								sx={{ marginBottom: "2%" }}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								{...register("phone")}
								id="phone"
								type="number"
								placeholder="Phone Number (optional)"
								variant="outlined"
								error={errors.phone}
								helperText={errors.phone ? "Phone number is not valid" : ""}
								fullWidth
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Avatar variant="inputAdorment" src={Phone} />
										</InputAdornment>
									),
								}}
								sx={{ marginBottom: "2%" }}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								{...register("feedback")}
								id="feedback"
								placeholder="How did you hear about us?"
								variant="outlined"
								fullWidth
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Avatar variant="inputAdorment" src={Question} />
										</InputAdornment>
									),
								}}
								sx={{ marginBottom: "2%" }}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<Button
								variant="primaryButton"
								value="submit"
								type="submit"
								size="large"
								sx={{ mariginBottom: 1 }}
								fullWidth
							>
								Signup
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="label"
								color="initial"
								textAlign={"start"}
								sx={{
									position: "sticky",
									whiteSpace: "break-spaces",
									wordWrap: "break-word",
								}}
							>
								By confirming your email, you agree to our&nbsp;
								<MuiLink
									sx={{ cursor: "pointer", textDecoration: "none" }}
									onClick={() => handleClickOpen("paper")}
								>
									Terms of Service
								</MuiLink>
								&nbsp;and that you have read and understood our&nbsp;
								<MuiLink
									sx={{ cursor: "pointer", textDecoration: "none" }}
									onClick={() => handleClickOpenPrivacy("paper")}
								>
									Privacy Policy
								</MuiLink>
								.
							</Typography>
						</Grid>
						<TermsModal
							open={open}
							setOpen={setOpen}
							scroll={scroll}
							content={termsAgreement}
							loading={loadingAgreements}
							title="Terms of Service"
							closeIcon={CloseModal}
							maxWidth={maxWidth}
							fullScreen={check}
						/>
						<TermsModal
							open={openPrivacy}
							setOpen={setOpenPrivacy}
							scroll={scroll}
							content={privacyAgreement}
							loading={loadingPrivacy}
							title="Privacy Policy"
							closeIcon={CloseModal}
							maxWidth={maxWidth}
							fullScreen={check}
						/>
					</Grid>
				</form>
			</Stack>
		</MainPage>
	);
}
