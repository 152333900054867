import React from "react";
//Third party imports
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import useTheme from "@mui/material/styles/useTheme";

//Internal imports
import "./Signup.css";
import SignUpForm from "./signUpForm";
import { MainPage } from "../templates/MainPage";
import { TermsModal } from "../templates/modals";
import { ChatContext } from "../../Context/ChatContext";
import CloseModal from "../../Sources/Icons/CloseModal.svg";
import SignUpGoogleButton from "./googleSignUpButton";
import { getByIdNoAuth } from "../../backend/services";
import { isMobile } from "../../hooks/utils";

export default function SignUp() {
	const { dispatchChat } = React.useContext(ChatContext);
	const check = isMobile();
	const [loadingAgreements, setLoadingAgreements] = React.useState(false);
	const [loadingPrivacy, setLoadingPrivacy] = React.useState(false);

	async function getAgreements() {
		//TODO[] change to service call, instead of getById
		setLoadingAgreements(true);
		await getByIdNoAuth({
			route: "agreement/getAgreementType",
			id: "TERMS",
		})
			.then((response) => {
				setTermsAgreement({ content: response.document.content });
				setLoadingAgreements(false);
			})
			.catch((error) => {
				console.error(error);
			});

		//TODO[] change to service call, instead of getById
		setLoadingPrivacy(true);
		await getByIdNoAuth({
			route: "agreement/getAgreementType",
			id: "PRIVACY",
		})
			.then((response) => {
				setPrivacyAgreement({ content: response.document.content });
				setLoadingPrivacy(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	React.useEffect(() => {
		dispatchChat({ type: "HIDE_CHAT" });
		getAgreements();
	}, []);

	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState("paper");

	const [openPrivacy, setOpenPrivacy] = React.useState(false);

	const [termsAgreement, setTermsAgreement] = React.useState({ content: "" });
	const [privacyAgreement, setPrivacyAgreement] = React.useState({
		content: "",
	});

	function handleClickOpenPrivacy(scrollType) {
		setOpenPrivacy(true);
		setScroll(scrollType);
	}

	function handleClickOpen(scrollType) {
		setOpen(true);
		setScroll(scrollType);
	}

	// const theme = useTheme();
	const maxWidth = "md";
	// const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

	return (
		<MainPage
			header={true}
			title="Sign Up"
			subtitle="Create your account to get started"
			width="30%"
			show={true}
			backFunction={0}
		>
			<SignUpGoogleButton />
			<Divider
				sx={{
					alignItems: "flex-start",
					gap: "15px",
				}}
				variant="mainDivider"
				flexItem
			>
				<Typography variant="caption">Or</Typography>
			</Divider>
			<SignUpForm />
			<Box sx={{ marginTop: 2, paddingBottom: "10%" }}>
				<Typography
					variant="label"
					color="initial"
					textAlign={"start"}
					sx={{
						position: "sticky",

						whiteSpace: "break-spaces",
						wordWrap: "break-word",
					}}
				>
					By confirming your email, you agree to our&nbsp;
					<MuiLink
						sx={{ cursor: "pointer", textDecoration: "none" }}
						onClick={() => handleClickOpen("paper")}
					>
						Terms of Service
					</MuiLink>
					&nbsp;and that you have read and understood our&nbsp;
					<MuiLink
						sx={{ cursor: "pointer", textDecoration: "none" }}
						onClick={() => handleClickOpenPrivacy("paper")}
					>
						Privacy Policy
					</MuiLink>
					.
				</Typography>
			</Box>

			<TermsModal
				open={open}
				setOpen={setOpen}
				scroll={scroll}
				content={termsAgreement}
				loading={loadingAgreements}
				title="Terms of Service"
				closeIcon={CloseModal}
				maxWidth={maxWidth}
				fullScreen={check}
			/>

			<TermsModal
				open={openPrivacy}
				setOpen={setOpenPrivacy}
				scroll={scroll}
				content={privacyAgreement}
				loading={loadingPrivacy}
				title="Privacy Policy"
				closeIcon={CloseModal}
				maxWidth={maxWidth}
				fullScreen={check}
			/>
		</MainPage>
	);
}
