import React from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";

import { MainModal } from "../templates/modals";
import {
	handleComplete,
	resetQuotes,
	setActiveStep,
	updateProject,
} from "../../features/project/projectSlice";

import { isMobile } from "../../hooks/utils";
import { constructPrice } from "../../hooks/localeString";

export default function CheckoutForm({ amount }) {
	const check = isMobile();
	const stripe = useStripe();
	const elements = useElements();

	// const { user } = useSelector((state) => state.auth);

	const [message, setMessage] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);

	const [openModal, setOpenModal] = React.useState(false);

	const projects = useSelector((state) => state.projectsRedux);

	const handleClose = () => {
		setOpenModal(false);
		setIsLoading(false);
	};

	const dispatch = useDispatch();

	React.useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
			case "succeeded":
				setMessage("Payment succeeded!");
				break;
			case "processing":
				setMessage("Your payment is processing.");
				break;
			case "requires_payment_method":
				setMessage("Your payment was not successful, please try again.");
				break;
			default:
				setMessage("Something went wrong.");
				break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		stripe
			.confirmPayment({
				elements,
				redirect: "if_required",
				// confirmParams: {
				// 	// Make sure to change this to your payment completion page
				// 	return_url: "/",
				// },
			})
			.then((result) => {
				if (result.error) {
					if (
						result.error.type === "card_error" ||
						result.error.type === "validation_error"
					) {
						setMessage(result.error.message);
						setOpenModal(true);
					} else {
						setMessage("An unexpected error occurred.");
					}
				} else {
					// successMessage = true;
					// postMethod({
					// 	route: "payments/paymentSucceeded",
					// 	body: {
					// 		quotes,
					// 	},
					// });
					dispatch(resetQuotes());
					if (!projects.project.completedSteps[5]) {
						dispatch(handleComplete(5));
					} else {
						dispatch(setActiveStep(6));
					}
					dispatch(updateProject());
					//TODO[x] refactor this, to use handle complete form redux 🥺
					// navigate("/invoice");
					// localStorage.setItem("payment", true);
				}
			});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		// if (error.type === "card_error" || error.type === "validation_error") {
		// 	setMessage(error.message);
		// } else {
		// 	setMessage("An unexpected error occurred.");
		// }
	};
	function dialogContent() {
		return <Typography sx={{ mt: 2 }}>{message}</Typography>;
	}

	function dialogActions() {
		return (
			<Button onClick={handleClose} variant="primaryButton">
				Close
			</Button>
		);
	}

	/* 	const options = {
		fields: {
			billingDetails: {
				name: "auto",
				email: "auto",
				phone: "never",
				address: "never",
			},
		},
		defaultValues: {
			billingDetails: {
				name: user.displayName,
				email: user.email,
			},
		},
	}; */

	return (
		<Box
			sx={{
				padding: "30px",
				background: "rgba(255, 255, 255, 0.8)",
				boxShadow: "8px 8px 20px rgba(192, 192, 192, 0.25)",
				backdropFilter: "blur(20px)",
				borderRadius: "15px",
				width: check ? "100%" : "400px",
				height: "fit-content",
				alignSelf: "center",
			}}
			component={"form"}
			id="payment-form"
			onSubmit={handleSubmit}
		>
			<PaymentElement /* options={options} */ id="payment-element" />
			<section className="confirm-pay-btn">
				<Typography textAlign={"center"} marginBottom={"8px"}>
					{isLoading || !stripe || !elements ? (
						<Skeleton />
					) : (
						`Total: ${constructPrice(amount / 100)}`
					)}
				</Typography>
				<Button
					variant="primaryButton"
					disabled={isLoading || !stripe || !elements}
					id="submit"
					type="submit"
				>
					<span className="button-text" id="button-text">
						{isLoading ? <></> : <Typography>Complete purchase</Typography>}
					</span>
				</Button>
			</section>

			<MainModal
				title={"Error"}
				open={openModal}
				content={dialogContent()}
				actions={dialogActions()}
				maxWidth={"md"}
				fullScreen={check}
				scroll="paper"
			/>
		</Box>
	);
}
