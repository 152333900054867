import ArrowBack from "../../../Sources/Icons/arrowBack.svg";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";

export function MobileHeader({
	show,
	title,
	avatar,
	avatarImg,
	subtitle,
	handleBack,
}) {
	return (
		<>
			{show ? (
				<Stack>
					<Stack
						direction="row"
						sx={{
							display: "grid",
							gridTemplateColumns: "29px 1fr",
						}}
					>
						<IconButton
							disableRipple
							sx={{
								position: "relative",
								left: "0",
								alignSelf: "flex-start",
							}}
							onClick={handleBack}
						>
							<Avatar variant="backArrowIcon" src={ArrowBack} />
						</IconButton>
						<Typography
							variant="titles"
							sx={{
								position: "relative",
								marginBottom: "27px",
								left: "0",
								justifySelf: "center",
							}}
						>
							{title}
						</Typography>
					</Stack>
					{avatar ? (
						<Stack
							alignItems="center"
							direction="row"
							sx={{
								marginBottom: "76.5px",
							}}
						>
							<Typography variant="descriptiveText">
								{subtitle}&nbsp;
							</Typography>
							<Avatar
								variant="WipAvatar"
								src={avatarImg}
								// Refactor this to make it dynamic using avatarImg prop ^
								alt="team member"
							/>
						</Stack>
					) : (
						<>
							{subtitle && (
								<Typography
									variant="descriptiveText"
									style={{
										marginBottom: "76.5px",
									}}
								>
									{subtitle}
								</Typography>
							)}
						</>
					)}
				</Stack>
			) : (
				<Stack>
					<Stack
						direction="row"
						sx={{
							display: "flex",
							// gridTemplateColumns: "29px 1fr",
						}}
					>
						<Typography
							variant="titles"
							sx={{
								position: "relative",
								marginBottom: "27px",
								left: "0",
								justifySelf: "center",
							}}
						>
							{title}
						</Typography>
					</Stack>
					{avatar ? (
						<Stack
							alignItems="center"
							direction="row"
							sx={{
								marginBottom: "76.5px",
							}}
						>
							<Typography variant="descriptiveText">
								{subtitle}&nbsp;
							</Typography>
							<Avatar
								variant="WipAvatar"
								src={avatarImg}
								// Refactor this to make it dynamic using avatarImg prop ^
								alt="team member"
							/>
						</Stack>
					) : (
						<>
							{subtitle && (
								<Typography
									variant="descriptiveText"
									style={{
										marginBottom: "76.5px",
									}}
								>
									{subtitle}
								</Typography>
							)}
						</>
					)}
				</Stack>
			)}
		</>
	);
}
