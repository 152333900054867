import React from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MuiLink from "@mui/material/Link";
// MUI ICONS
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { getProjectsByUserId } from "../../hooks/projects";
import { setToken, setUser } from "../../features/auth/authSlice";
import {
	setActiveProjectById,
	setProjects,
} from "../../features/project/projectSlice";
import { Button, useTheme } from "@mui/material";
import { login } from "../../hooks/auth";
import ResetPassword from "./ResetPassword";
import { BackdropContext } from "../../Context/BackdropContext";

export default function LoginForm() {
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = React.useState(false);
	function handleClickShowPassword() {
		setShowPassword(!showPassword);
	}

	// const paperStyle = {
	// 	width: "80%", // Puedes ajustar el valor según tus necesidades
	// 	margin: "auto", // Centrar horizontalmente el MuiPaper
	//   };

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	function handleMouseDownPassword(event) {
		event.preventDefault();
	}

	async function handleSubmit(values) {
		try {
			// First login then get projects
			changeLoadingBackdrop(true);
			const { user, firebaseUser } = await login(values.email, values.password);
			// then set user in redux store
			dispatch(setToken(firebaseUser.user.accessToken));
			dispatch(setUser(user));
			// now get projects from user
			const projects = await getProjectsByUserId(
				user.uid,
				firebaseUser.user.accessToken
			);

			// next step is add projects in redux store then redirect to nda page
			dispatch(setProjects(projects));
			dispatch(setActiveProjectById(user.activeProject));
			changeLoadingBackdrop(false);
		} catch (e) {
			changeLoadingBackdrop(false);
			console.error("Error =>", e);
		}
	}
	const validationSchema = yup.object({
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),
		password: yup.string().required("Password is required"),
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			await handleSubmit(values);
		},
	});
	return (
		<Box
			component={"form"}
			sx={{
				"& .MuiFormHelperText-root": {
					backgroundColor: "transparent",
				},
				"& .MuiInputBase-input": {
					backgroundColor: "white",
				},
			}}
			onSubmit={formik.handleSubmit}
		>
			<Grid sx={{ marginTop: 1 }} container spacing={2}>
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant="outlined"
						placeholder="email"
						id="email"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={(formik.touched.email && formik.errors.email) || " "}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant="outlined"
						type={showPassword ? "text" : "password"}
						id="password"
						name="password"
						placeholder="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={
							(formik.touched.password && formik.errors.password) || ""
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: 1,
						}}
					>
						<MuiLink
							sx={{
								cursor: "pointer",
								color: useTheme().palette.lightBlue.main,
								fontSize: "1.2rem",
							}}
							onClick={() => setOpen(true)}
						>
							Forgot password?
						</MuiLink>
					</Box>
				</Grid>
				<Grid display="flex" justifyContent="center" item xs={12}>
					<Button
						variant="primaryButton"
						type="submit"
						sx={{ minWidth: "50%" }}
					>
						Log In
					</Button>
				</Grid>
			</Grid>
			<ResetPassword open={open} setOpen={setOpen} />
		</Box>
	);
}
