import React from "react";
import { useNavigate } from "react-router-dom";
import { Question } from "./Question.js";
import api from "../../config/apiConfig";

import "./Questionnarie.css";
import "./Loader.css";

function Questionnarie() {
	const [questions, setQuestions] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [idQuestion, setIdQuestion] = React.useState("Q");
	const [idPrevQuestion, setIdPrevQuestion] = React.useState("Q");
	const [awnsers, setAwnsers] = React.useState([]);
	const [nextQuestionId, setNextQuestionId] = React.useState("Q");
	const [currentQuestion, setCurrentQuestion] = React.useState(null);
	// const [end, setEnd] = useState(false);

	const navigate = useNavigate();

	// props.setActiveStep(2);

	React.useEffect(() => {
		loadQuestions();
	}, []);

	React.useEffect(() => {
		// btnDisabled();
		if (!loading) {
			setCurrentQuestion(questions.filter(getQuestionById)[0]);
			gesionAnimacionQuestion(".questions-container");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idQuestion]);

	React.useEffect(() => {
		if (nextQuestionId === "END") {
			sendAwnsers();
			navigate("/RequirementsDescription");
			return;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [awnsers]);

	async function loadQuestions() {
		try {
			const request = await fetch(
				api.url + "/questinnarie/getQuestionnaire/NSEap6Uj8LAawdwIWwlV"
			);
			const request2Json = await request.json();

			setQuestions(request2Json.questionnaire.questionnaire);
			setCurrentQuestion(request2Json.questionnaire.questionnaire[0]);
			setLoading(false);
		} catch (e) {
			console.error(e);
		}
	}
	function gesionAnimacionQuestion() {
		/*document.querySelector(target).classList.remove("fadeIn");
        document.querySelector(target).classList.add("fadeOut");

        setTimeout(() => {
            document.querySelector(target).classList.add("fadeIn");
            document.querySelector(target).classList.remove("fadeOut");
        }, 500);*/
	}

	// function btnDisabled() {
	// 	const btnPrev = document.querySelector("#btnPrev");
	// 	const btnNext = document.querySelector("#btnNext");

	// 	if (idQuestion == "Q") {
	// 		btnPrev.disabled = true;
	// 	} else {
	// 		btnPrev.disabled = false;
	// 	}
	// }

	function handelPrevQuestionBtn() {
		setIdQuestion(idPrevQuestion);
		// setIdPrevQuestion(indexPrevQuestion - 1);
	}

	function handelNextQuestionBtn() {
		if (nextQuestionId === "END") {
			return;
		}

		setIdPrevQuestion(idQuestion);
		setIdQuestion(nextQuestionId);
	}

	async function sendAwnsers() {
		const postAwnsers = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(awnsers),
		};

		try {
			await fetch("https://apimocha.com/awnsers/setAwnsers", postAwnsers);
		} catch (e) {
			console.error(e);
		}
	}

	function handleInput(next) {
		setNextQuestionId(next);
		// setNextQuestion(next);
	}

	function handleSubmit(e) {
		e.preventDefault();

		let awnserBringed = "";
		if (e.target.opciones) {
			awnserBringed = e.target.opciones.value;
		} else if (e.target.respuestaAbierta.value !== "") {
			awnserBringed = e.target.respuestaAbierta.value;
			const awnser = [
				{
					Question: e.target.question.value,
					respuesta: awnserBringed,
				},
			];

			if (e.target.next) {
				setAwnsers([...awnsers, ...awnser]);
			}

			handelNextQuestionBtn();
		} else {
			alert("Your answer is empty");
		}
	}

	function getQuestionById(question) {
		if (question["id"] === idQuestion) {
			return true;
		}
		return false;
	}

	return (
		<main className="questionnarie-container">
			<div className="questionnarie-box">
				<div className="header">
					<button className="btn btn-back">Back</button>
				</div>
				<h2 className="titulo-ques">Questionnaire</h2>
				{!loading ? (
					<form onSubmit={handleSubmit}>
						<Question question={currentQuestion} handleInput={handleInput} />
						<input
							type="hidden"
							id="nextQuestion"
							value={currentQuestion["question-next"]}
							name="nextQuestion"
						/>
						<input
							type="hidden"
							value={currentQuestion["question"]}
							name="question"
						/>
						<button
							style={{ marginRigth: "0px", display: "none" }}
							className="btn btn-prev"
							id="btnPrev"
							type="button"
							onClick={handelPrevQuestionBtn}
						>
							Previous question
						</button>

						<button
							style={{ marginLeft: "0px" }}
							className="btn btn-next"
							id="btnNext"
							name="next"
							type="submit"
						>
							Next question
						</button>
					</form>
				) : (
					<Loader />
				)}
			</div>
		</main>
	);
}

export { Questionnarie };

function Loader() {
	return (
		<div className="loading-box">
			<div className="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<p>Generating the questionnarie</p>
		</div>
	);
}
