import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { /* getFirestore, */ initializeFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

import firebaseConfig from "./config/FirebaseConfig";

//initialitaze firebase
const app = initializeApp(firebaseConfig);

// export const auth = getAuth(app);

export const db = initializeFirestore(app, {
	experimentalAutoDetectLongPolling: true,
});
// export const db = getFirestore(app);

export const auth = getAuth(app);
export const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("email");
export default app;

export { googleProvider };
