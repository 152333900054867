import React from "react";

export const ChatContext = React.createContext();
export const ChatContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		Hidden: false,
		ClientActiveChat: "",
		ChatID: "",
		ClientActiveChatUsername: "",
		photoURL: "",
		messages: [],
		sendedAnswers: false,
	};
	const chatReducer = (state, action) => {
		switch (action.type) {
		case "CHANGE_USER":
			return {
				...state,
				ClientActiveChat: action.payload.ClientActiveChat,
				ChatID: action.payload.ChatID,
				ClientActiveChatUsername: action.payload.ClientActiveChatUsername,
				photoURL: action.payload.photoURL,
			};
		case "HIDE_CHAT":
			return { ...state, Hidden: false };
		case "SHOW_CHAT":
			return { ...state, Hidden: true };
		case "SET_MESSAGES":
			return { ...state, messages: action.payload };
		case "RESET_CHAT":
			return {
				state: INITIAL_STATE,
			};
		case "SET_SENDED_ANSWERS":
			return { ...state, sendedAnswers: action.payload };

		default:
			return state;
		}
	};
	const [state, dispatchChat] = React.useReducer(chatReducer, INITIAL_STATE);
	return (
		<ChatContext.Provider value={{ chatData: state, dispatchChat }}>
			{children}
		</ChatContext.Provider>
	);
};
