//! mui imports
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CardMedia from "@mui/material/CardMedia";
// import Card from "@mui/material/Card";
// import mae from "../../Sources/images/desintegratingMae.png";
import { styled } from "@mui/material";

import "./NotFound.css";
import { MainPage } from "../templates/MainPage";

const BackContainer = styled(Paper)(({ theme }) => ({
	// backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	// backdropFilter: "blur(10px)",
	...theme.typography.body2,
	textAlign: "center",
	padding: theme.spacing(2),
	color: theme.palette.text.secondary,
	display: "flex",
	flexDirection: "column",
	width: "70%",
	margin: "0px auto",
	alignItems: "center",
	backgroundColor: "#ffffff99",
	borderRadius: "9px",
	boxShadow: "2px 2px 15px #E9E9E9",
	backdropFilter: `blur(10px)`,
}));

function NotFound() {
	return (
		<MainPage
			title={"Error 404 - page not Found"}
			subtitle="please go back to main page"
			show={true}
		>
			<BackContainer elevation={0}>
				{/* <Typography variant="titles">Your Mother</Typography> */}
				<CardMedia
					sx={
						{
							// maxWidth: "60vw",
							// filter: "saturate(50) hue-rotate(335deg)",
						}
					}
					component={"img"}
					// src="https://i.redd.it/hluydaa98sq51.gif"
					// src={mae}
					// sx={}
					alt="404 image"
				/>
				<Box>
					<Button href={"/"} variant="primaryButton">
						Go to main page
					</Button>
				</Box>
			</BackContainer>
		</MainPage>
	);
}

export { NotFound };
