// To parse this data:
//
//   const Convert = require("./file");
//
//   const ticketTableModel = Convert.toTicketTableModel(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
function toTicketTableModel(json) {
	return cast(JSON.parse(json), r("TicketTableModel"));
}

function ticketTableModelToJson(value) {
	return JSON.stringify(uncast(value, r("TicketTableModel")), null, 2);
}
/* 
function toMedia(json) {
	return cast(JSON.parse(json), r("Media"));
}

function mediaToJson(value) {
	return JSON.stringify(uncast(value, r("Media")), null, 2);
}

function toPlatform(json) {
	return cast(JSON.parse(json), r("Platform"));
}

function platformToJson(value) {
	return JSON.stringify(uncast(value, r("Platform")), null, 2);
}

function toTaskWriter(json) {
	return cast(JSON.parse(json), r("TaskWriter"));
}

function taskWriterToJson(value) {
	return JSON.stringify(uncast(value, r("TaskWriter")), null, 2);
}
 */
function invalidValue(typ, val, key, parent = "") {
	const prettyTyp = prettyTypeName(typ);
	const parentText = parent ? ` on ${parent}` : "";
	const keyText = key ? ` for key "${key}"` : "";
	throw Error(
		`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
			val
		)}`
	);
}

function prettyTypeName(typ) {
	if (Array.isArray(typ)) {
		if (typ.length === 2 && typ[0] === undefined) {
			return `an optional ${prettyTypeName(typ[1])}`;
		} else {
			return `one of [${typ
				.map((a) => {
					return prettyTypeName(a);
				})
				.join(", ")}]`;
		}
	} else if (typeof typ === "object" && typ.literal !== undefined) {
		return typ.literal;
	} else {
		return typeof typ;
	}
}

function jsonToJSProps(typ) {
	if (typ.jsonToJS === undefined) {
		const map = {};
		typ.props.forEach((p) => (map[p.json] = { key: p.js, typ: p.typ }));
		typ.jsonToJS = map;
	}
	return typ.jsonToJS;
}

function jsToJSONProps(typ) {
	if (typ.jsToJSON === undefined) {
		const map = {};
		typ.props.forEach((p) => (map[p.js] = { key: p.json, typ: p.typ }));
		typ.jsToJSON = map;
	}
	return typ.jsToJSON;
}

function transform(val, typ, getProps, key = "", parent = "") {
	function transformPrimitive(typ, val) {
		if (typeof typ === typeof val) return val;
		return invalidValue(typ, val, key, parent);
	}

	function transformUnion(typs, val) {
		// val must validate against one typ in typs
		const l = typs.length;
		for (let i = 0; i < l; i++) {
			const typ = typs[i];
			try {
				return transform(val, typ, getProps);
			} catch (error) {
				// ignore
			}
		}
		return invalidValue(typs, val, key, parent);
	}

	function transformEnum(cases, val) {
		if (cases.indexOf(val) !== -1) return val;
		return invalidValue(
			cases.map((a) => {
				return l(a);
			}),
			val,
			key,
			parent
		);
	}

	function transformArray(typ, val) {
		// val must be an array with no invalid elements
		if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
		return val.map((el) => transform(el, typ, getProps));
	}

	function transformDate(val) {
		if (val === null) {
			return null;
		}
		const d = new Date(val);
		if (isNaN(d.valueOf())) {
			return invalidValue(l("Date"), val, key, parent);
		}
		return d;
	}

	function transformObject(props, additional, val) {
		if (val === null || typeof val !== "object" || Array.isArray(val)) {
			return invalidValue(l(ref || "object"), val, key, parent);
		}
		const result = {};
		Object.getOwnPropertyNames(props).forEach((key) => {
			const prop = props[key];
			const v = Object.prototype.hasOwnProperty.call(val, key)
				? val[key]
				: undefined;
			result[prop.key] = transform(v, prop.typ, getProps, key, ref);
		});
		Object.getOwnPropertyNames(val).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(props, key)) {
				result[key] = val[key];
			}
		});
		return result;
	}

	if (typ === "any") return val;
	if (typ === null) {
		if (val === null) return val;
		return invalidValue(typ, val, key, parent);
	}
	if (typ === false) return invalidValue(typ, val, key, parent);
	let ref = undefined;
	while (typeof typ === "object" && typ.ref !== undefined) {
		ref = typ.ref;
		typ = typeMap[typ.ref];
	}
	if (Array.isArray(typ)) return transformEnum(typ, val);
	if (typeof typ === "object") {
		// eslint-disable-next-line
		return typ.hasOwnProperty("unionMembers")
			? transformUnion(typ.unionMembers, val)
			: // eslint-disable-next-line
			typ.hasOwnProperty("arrayItems")
				? transformArray(typ.arrayItems, val)
				: // eslint-disable-next-line
			typ.hasOwnProperty("props")
					? transformObject(getProps(typ), typ.additional, val)
					: invalidValue(typ, val, key, parent);
	}
	// Numbers can be parsed by Date but shouldn't be.
	if (typ === Date && typeof val !== "number") return transformDate(val);
	return transformPrimitive(typ, val);
}

function cast(val, typ) {
	return transform(val, typ, jsonToJSProps);
}

function uncast(val, typ) {
	return transform(val, typ, jsToJSONProps);
}

function l(typ) {
	return { literal: typ };
}

function a(typ) {
	return { arrayItems: typ };
}

function u(...typs) {
	return { unionMembers: typs };
}

function o(props, additional) {
	return { props, additional };
}

/* function m(additional) {
	return { props: [], additional };
} */

function r(name) {
	return { ref: name };
}

const typeMap = {
	TicketTableModel: o(
		[
			{ json: "id", js: "id", typ: "" },
			{ json: "projectName", js: "projectName", typ: "" },
			{ json: "taskTitle", js: "taskTitle", typ: "" },
			{ json: "deadLine", js: "deadLine", typ: 0 },
			{ json: "messages", js: "messages", typ: 0 },
			{ json: "clientName", js: "clientName", typ: "" },
			{ json: "taskWriter", js: "taskWriter", typ: r("TaskWriter") },
			{ json: "platforms", js: "platforms", typ: a(r("Platform")) },
			{ json: "taskDescription", js: "taskDescription", typ: u(undefined, "") },
			{ json: "media", js: "media", typ: a(r("Media")) },
			{ json: "status", js: "status", typ: "" },
		],
		false
	),
	Media: o(
		[
			{ json: "key", js: "key", typ: "" },
			{ json: "media", js: "media", typ: "" },
		],
		false
	),
	Platform: o(
		[
			{ json: "name", js: "name", typ: "" },
			{ json: "icon", js: "icon", typ: "" },
		],
		false
	),
	TaskWriter: o(
		[
			{ json: "key", js: "key", typ: "" },
			{ json: "avatarUrl", js: "avatarUrl", typ: "" },
			{ json: "name", js: "name", typ: "" },
		],
		false
	),
};

module.exports = {
	ticketTableModelToJson: ticketTableModelToJson,
	toTicketTableModel: toTicketTableModel,
};
