import React from "react";

//Third party imports

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import { useForm } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { enqueueSnackbar } from "notistack";

//Internal imports
import { persistor } from "../../Store";
import { createUser, updateClientDb } from "../../hooks/auth";
import { createProject } from "../../hooks/projects";
import {
	setActiveProjectId,
	setProject,
	setProjects,
} from "../../features/project/projectSlice";
import { setToken, setUser } from "../../features/auth/authSlice";

import person from "../../Sources/Icons/Person.svg";
import Email from "../../Sources/Icons/Email.svg";
import Company from "../../Sources/Icons/Company.svg";
import Key from "../../Sources/Icons/Key.svg";
import Phone from "../../Sources/Icons/Phone.svg";
import Question from "../../Sources/Icons/Question.svg";
import { BackdropContext } from "../../Context/BackdropContext";
import { purgeRequests } from "../../features/requests/requestsSlice";

export default function SignUpForm() {
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfPassword, setShowConfPassword] = React.useState(false);

	const { nda, tasks } = useSelector((state) => state.requestsSlice);

	// const navigate = useNavigate();

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);
	const dispatch = useDispatch();

	const phoneShema = yup.string().phone("US", true);

	const validationSchema = yup.object({
		firstName: yup
			.string("Enter your first name")
			.min(1, "First name should be of minimum 1 character length")
			.required("First Name is required"),
		lastName: yup
			.string("enter your last name")
			.min(1, "Last name should be of m minimum 1 character length")
			.required("Last name is required"),
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),
		password: yup
			.string("Enter your password")
			.min(6, "Password should be of minimum 6 characters length")
			.required("Password is required"),
		passwordConfirmation: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match")
			.required("Password confirmation is required"),
		phone: yup.string().test("test-phone", "Invalid phone number", (value) => {
			if (value) return phoneShema.isValidSync(value);
			return true;
		}),
		feedback: yup.string(),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			phone: "",
		},
		mode: "onSubmit",
	});

	const projects = useSelector((state) => state.projectsRedux);

	async function onSubmit(data) {
		// removing this validation for now
		/*
		if (projects.platforms.length === 0) {
			enqueueSnackbar("No platforms selected!", { variant: "error" });
			return;
		} */

		try {
			// create user in firebase auth and firestore db
			changeLoadingBackdrop(true);
			const { user, firebaseUser } = await createUser(
				data.email,
				data.password,
				data
			);
			// then set user token in redux store
			dispatch(setToken(firebaseUser.user.accessToken));

			// create project in firestore db
			// this will be refactored to adjust the elimination of the platforms step

			const project = {
				clientUid: user.uid,
				clientName: user.displayName,
				platforms: projects.platforms,
				activeStep: nda ? 2 : 3,
				email: data.email,
				requireNda: nda,
				requests: tasks,
				completedSteps: [
					true,
					true, // <- this step is exclusive to google clients, so we set it to true
					!nda,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				],
				lastCompletedStep: nda ? 1 : 2,
			};

			const createdProject = await createProject(
				project,
				firebaseUser.user.accessToken
			);

			const updatedUser = {
				...user,
				activeProject: createdProject.uid,
			};

			await updateClientDb(updatedUser, firebaseUser.user.accessToken);
			
			dispatch(setUser(updatedUser));
			// then set project in redux store
			dispatch(setProject(createdProject));
			dispatch(setActiveProjectId(createdProject.uid));
			dispatch(setProjects([createdProject]));
			persistor.pause();
			persistor.purge();
			dispatch(purgeRequests());
			persistor.persist();
			changeLoadingBackdrop(false);

			// get reciently created project from firestore db
			/* 			setTimeout(async () => {
				const project = await getProjectById(
					createdProject.uid,
					firebaseUser.user.accessToken
				);
				const updatedUser = {
					...user,
					activeProject: project.uid,
				};
				await updateClientDb(updatedUser, firebaseUser.user.accessToken);
				dispatch(setUser(updatedUser));
				// then set project in redux store
				dispatch(setProject(project));
				dispatch(setActiveProjectId(project.uid));
				dispatch(setProjects([project]));
				persistor.pause();
				persistor.purge();
				dispatch(purgeRequests());
				persistor.persist();
				changeLoadingBackdrop(false);
			}, 1000); */
		} catch (error) {
			console.error(error);
			enqueueSnackbar(error, { variant: "error" });
			changeLoadingBackdrop(false);
		}
	}

	/* Passwords */
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowConfPassword = () => {
		setShowConfPassword(!showConfPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Box
			component={"form"}
			sx={{
				"& .MuiFormHelperText-root": {
					backgroundColor: "transparent",
				},
				"& .MuiInputBase-input": {
					backgroundColor: "white",
				},
				display: "flex",
				flexDirection: "column",
				paddingTop: 5,
				paddingBottom: 1,
			}}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Grid
				direction="row"
				justifyContent="center"
				alignItems="center"
				container
				spacing={0}
			>
				<Grid item xs={12}>
					<TextField
						{...register("firstName")}
						id="firstName"
						className="inputItem"
						placeholder="First Name"
						variant="outlined"
						error={errors.firstName}
						helperText={errors.firstName ? errors.firstName.message : " "}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={person} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("lastName")}
						id="lastName"
						placeholder="Last name"
						variant="outlined"
						error={errors.lastName}
						helperText={errors.lastName ? errors.lastName.message : " "}
						fullWidth
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={person} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("company")}
						id="website"
						placeholder="Website (optional)"
						variant="outlined"
						fullWidth
						size="small"
						helperText=" "
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={Company} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("email")}
						id="email"
						placeholder="Email"
						variant="outlined"
						error={errors.email}
						helperText={errors.email ? errors.email.message : " "}
						fullWidth
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={Email} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("password")}
						type={showPassword ? "text" : "password"}
						id="password"
						placeholder="Password"
						variant="outlined"
						error={errors.password}
						helperText={errors.password ? errors.password?.message : " "}
						fullWidth
						size="small"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										variant="iconVisibility"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
									>
										{showPassword ? (
											<VisibilityOffIcon sx={{ color: "#C0C0C0" }} />
										) : (
											<VisibilityIcon sx={{ color: "#C0C0C0" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={Key} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("passwordConfirmation")}
						type={showConfPassword ? "text" : "password"}
						id="passwordConfirmation"
						placeholder="Confirm password"
						variant="outlined"
						fullWidth
						size="small"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowConfPassword}
										onMouseDown={handleMouseDownPassword}
										variant="iconVisibility"
										edge="end"
									>
										{showConfPassword ? (
											<VisibilityOffIcon sx={{ color: "#C0C0C0" }} />
										) : (
											<VisibilityIcon sx={{ color: "#C0C0C0" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={Key} />
								</InputAdornment>
							),
						}}
						error={errors.passwordConfirmation}
						helperText={
							errors.passwordConfirmation
								? errors.passwordConfirmation?.message
								: " "
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("phone")}
						name="phone"
						placeholder="Phone Number (optional)"
						fullWidth
						variant="outlined"
						size="small"
						error={errors.phone}
						helperText={errors.phone ? errors.phone?.message : " "}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={Phone} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register("feedback")}
						id="feedback"
						placeholder="How did you hear about us?"
						fullWidth
						variant="outlined"
						helperText=" "
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="inputAdorment" src={Question} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="primaryButton"
						type="submit"
						value="submit"
						size="large"
						sx={{
							textTransform: "none",
							marginTop: 4,
							borderRadius: "11px",
						}}
						fullWidth
					>
						Create your account
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
