import React from "react";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadString,
} from "firebase/storage";
import SignatureCanvas from "react-signature-canvas";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "./Nda.css";

import { ChatContext } from "../../Context/ChatContext";
import { BackdropContext } from "../../Context/BackdropContext";

import { getById } from "../../backend/services";
import { MainPage } from "../templates/MainPage";

import {
	handleComplete,
	setActiveStep,
	setProject,
	updateProject,
} from "../../features/project/projectSlice";
// import { updateClientDb } from "../../hooks/auth";
import { saveSignedAgreement } from "../../hooks/projects";
// import { updateUser } from "../../features/auth/authSlice";

import { isMobile } from "../../hooks/utils";
import { enqueueSnackbar } from "notistack";

function Nda() {
	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);
	const dispatch = useDispatch();
	const check = isMobile();
	const [newAgreement, setNewAgreement] = React.useState({
		uid: "",
		content: "",
	});

	const storage = getStorage();

	const formSchema = yup.object().shape({
		imageUrl_: yup
			.string()
			.required()
			.matches(
				/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
				"Signature must be png"
			),
		fullName: yup
			.string("Enter your full name")
			.matches(/^[A-Za-z ]*$/, "Please enter full name")
			.required("Enter your full name"),
	});

	const {
		handleSubmit,
		register,
		formState: { errors, isValid },
		control,
		reset,
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(formSchema),
		defaultValues: {
			fullName: auth.user.displayName,
		},
	});

	const theme = useTheme();

	React.useEffect(() => {
		async function setAgreement() {
			await getAgreementNew();
		}
		setAgreement();
		dispatchChat({ type: "HIDE_CHAT" });
	}, []);

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	const { dispatchChat } = React.useContext(ChatContext);

	let sigCanvas = useRef({});
	const day = new Date().toLocaleDateString("en-US", {
		day: "numeric",
	});
	const month = new Date().toLocaleDateString("en-US", {
		month: "long",
	});
	const year = new Date().toLocaleDateString("en-US", {
		year: "numeric",
	});
	async function getAgreementNew() {
		try {
			changeLoadingBackdrop(true);
			await getById({
				route: "agreement/getAgreementType",
				id: "NDA",
			}).then((response) => {
				const formattedAgreement = response.document.content
					.replace("${day}", day)
					.replace("${month}", month)
					.replace("${year}", year)
					.replace("${userName}", auth.user.displayName);
				setNewAgreement({
					uid: response.document.uid,
					content: formattedAgreement,
				});

				changeLoadingBackdrop(false);
			});
		} catch (error) {
			changeLoadingBackdrop(false);
		}
	}

	function formatIntoPng() {
		if (sigCanvas.current) {
			const dataURL = sigCanvas.current.toDataURL();
			return dataURL;
		}
	}

	async function onSubmit(data) {
		try {
			const storageRef = ref(
				storage,
				`agreementsSigns/${auth.user.uid}/${
					projects.project.uid
				}/signature${new Date().getTime()}.png`
			);
			changeLoadingBackdrop(true);
			const projectCopy = { ...projects.project };
			// const accessToken = auth.token;
			projectCopy.isNdaSigned = true;

			//todo[] vvvv change to async Thunk and create a new function to update the client
			// await updateClientDb(userCopy, accessToken);

			if (!projects.project.completedSteps[2]) {
				await uploadString(storageRef, data.imageUrl_, "data_url");
				const signStorage = await getDownloadURL(storageRef);

				const signedData = {
					clientUid: auth.user.uid,
					agreementId: newAgreement.uid,
					contentAgreement: newAgreement.content,
					imgUrlSigned: data.imageUrl_,
					storageRef: storageRef.fullPath,
					storageUrl: signStorage,
					fullName: data.fullName,
					email: auth.user.email,
					projectUid: projects.project.uid,
					projectName: projects.project.projectName,
					date: { day, month, year },
				};

				await saveSignedAgreement(signedData, auth.token);
				dispatch(setProject(projectCopy));
				dispatch(handleComplete(2));
			} else {
				await uploadString(storageRef, data.imageUrl_, "data_url");
				const signStorage = await getDownloadURL(storageRef);

				const signedData = {
					clientUid: auth.user.uid,
					agreementId: newAgreement.uid,
					contentAgreement: newAgreement.content,
					imgUrlSigned: data.imageUrl_,
					storageRef: storageRef.fullPath,
					storageUrl: signStorage,
					fullName: data.fullName,
					email: auth.user.email,
					projectUid: projects.project.uid,
					projectName: projects.project.projectName,
					date: { day, month, year },
				};

				await saveSignedAgreement(signedData, auth.token);
				dispatch(setProject(projectCopy));
				dispatch(setActiveStep(3));
			}
			dispatch(updateProject());
			changeLoadingBackdrop(false);
		} catch (error) {
			changeLoadingBackdrop(false);
			console.error(error);
			enqueueSnackbar(error, { variant: "error" });
		}
	}

	React.useEffect(() => {
		if (auth.user?.displayName) {
			reset({ fullName: auth.user.displayName });
		}
	}, [auth.user?.displayName]);

	return (
		<MainPage
			header={true}
			title="Non Disclousure Agreement"
			subtitle="Sign the confidenciality agreement so you can send all requirements."
			show={false}
		>
			<Paper
				component={"form"}
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					boxShadow: "none",
					backgroundColor: "transparent",
					maxWidth: "960px",
					width: "100%",
				}}
			>
				<Paper
					variant="translucidPagePaper"
					sx={{
						paddingX: check ? "10%" : "70px",
						paddingBottom: "30px",
						minWidth: "100%",
						boxShadow: "8px 8px 20px rgba(192, 192, 192, 0.25)",
						// width: width ? width : "40%",
						[theme.breakpoints.down("md")]: {
							width: "50%",
						},
						[theme.breakpoints.down("lg")]: {
							// width: `calc(100% - ${theme.spacing(10)})`,
							width: `80%`,
						},
						[theme.breakpoints.up("lg")]: {
							// width: widthPlatform,
							fullWidth: true,
						},
						[theme.breakpoints.up("xl")]: {
							width: "90%",
						},
					}}
				>
					<Typography variant="NdaTitle" textAlign={"center"} paddingY={"30px"}>
						Non Disclousure Agreement
					</Typography>
					<Typography
						variant="tos"
						sx={{
							paddingLeft: 1,
							minWidth: "100%",
							[theme.breakpoints.down("md")]: {
								paddingLeft: 0,
							},
						}}
						dangerouslySetInnerHTML={{ __html: newAgreement.content }}
					/>
					{projects.project.isNdaSigned ? (
						<Typography
							style={{ textAlign: "center", marginBottom: "30px" }}
							variant="titles"
						>
							You have already signed the contract.
						</Typography>
					) : (
						<Box
							sx={{
								marginTop: "auto",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
							<Stack>
								<Typography
									variant="descriptiveText"
									sx={{ marginBottom: "5px", marginTop: "5px" }}
								>
									Your signature
								</Typography>
								<Typography
									variant="descriptiveText"
									sx={{
										color: "#bf1650",
										fontWeight: "bolder",
									}}
								>
									{errors.imageUrl_?.message}
								</Typography>
								<Box
									sx={{
										backgroundColor: "#ddd",
										borderRadius: "2px",
										border: "grey dashed 2px",
										marginTop: "10px",
										display: "flex",
										flexDirection: "column",
										marginRight: 2,
										width: check ? "100%" : "300px",
										[theme.breakpoints.down("md")]: {
											height: "300px",
										},
									}}
								>
									<Button
										disableRipple
										sx={{
											":hover": {
												backgroundColor: "#000",
											},
											backgroundColor: "#a1a1a1",
											margin: "10px 10px",
											color: "#fff",
											placeSelf: "flex-start",
										}}
										zIndex={"fab"}
										onClick={() => sigCanvas.current.clear()}
									>
										Clear
									</Button>
									<Divider />
									<Controller
										name="imageUrl_"
										control={control}
										render={({ field }) => (
											<SignatureCanvas
												penColor="black"
												canvasProps={{ className: "sign-canvas" }}
												ref={sigCanvas}
												onEnd={() => field.onChange(formatIntoPng())}
											/>
										)}
									/>
								</Box>
							</Stack>
						</Box>
					)}
				</Paper>
				{projects?.project?.isNdaSigned ? (
					<Stack
						sx={{
							marginTop: 1,
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "flex-end",
							paddingBottom: 8,
							paddingTop: 2,
							marginRight: 2,
						}}
					>
						<Button
							variant="primaryButton"
							sx={{ width: check ? "100%" : "20%" }}
							onClick={() => {
								dispatch(setActiveStep(2));
								dispatch(updateProject());
							}}
						>
							Continue
						</Button>
					</Stack>
				) : (
					<Box sx={{ backgroundColor: "" }}>
						{check ? (
							<Stack sx={{ height: "100%" }} direction={"column"}>
								<InputLabel
									sx={{
										color: "#285D69",
										fontFamily: "Ulagadi Sans",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "14px",
										marginBottom: 0.5,
										marginLeft: 2,
									}}
								>
									Your full name:
								</InputLabel>
								<Stack
									spacing={2}
									direction={"column"}
									sx={{
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<FormControl
										sx={{
											width: "100%",
											height: "100%",
										}}
									>
										<TextField
											{...register("fullName")}
											name={"fullName"}
											size="small"
											error={errors.fullName}
											fullWidth
											sx={{
												background: "rgba(255, 255, 255, 0.8)",
												backdropFilter: "blur(10px)",
												borderRadius: "9px",
											}}
											helperText={
												errors.fullName ? errors.fullName.message : " "
											}
										/>
									</FormControl>
									<Stack alignItems="center" sx={{ width: "150px" }}>
										<Button
											type="submit"
											fullWidth
											variant="primaryButton"
											disabled={!isValid}
											sx={{
												marginBottom: check ? 4 : 0,
											}}
										>
											Next
										</Button>
										{/* 										
										{isValid ? null : (
											<MuiLink
												sx={{
													cursor: "pointer",
													color: "#000",
													":visited": {
														color: "inherit",
													},
												}}
											>
												Skip
											</MuiLink>
										)} */}
									</Stack>
								</Stack>
							</Stack>
						) : (
							<Stack
								direction={"column"}
								sx={{
									marginTop: 9,
									justifyContent: "space-between",
									paddingBottom: 7,
									width: "100%",
								}}
							>
								<InputLabel
									sx={{
										color: "#285D69",
										top: "5px",
										width: "102px",
										height: "19px",
										fontFamily: "Ulagadi Sans",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "14px",
										marginBottom: 1,
									}}
								>
									Your full name:
								</InputLabel>
								<Stack
									direction={"row"}
									sx={{
										justifyContent: "space-between",
									}}
								>
									<FormControl
										sx={{
											width: "300px",
											height: "100%",
										}}
									>
										<TextField
											size="small"
											error={errors.fullName}
											fullWidth
											sx={{
												height: "22px",
												background: "rgba(255, 255, 255, 0.8)",
												backdropFilter: "blur(10px)",
												borderRadius: "9px",
											}}
											{...register("fullName")}
										/>
									</FormControl>
									<Stack alignItems="center" sx={{ width: "150px" }}>
										<Button
											type="submit"
											fullWidth
											variant="primaryButton"
											disabled={!isValid}
										>
											Next
										</Button>
									</Stack>
								</Stack>
							</Stack>
						)}
					</Box>
				)}
			</Paper>
		</MainPage>
	);
}

export { Nda };
