import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

import "./Payment.css";
import CheckoutForm from "./CheckoutForm";
import { BackdropContext } from "../../Context/BackdropContext";
import { ChatContext } from "../../Context/ChatContext";
import { MainPage } from "../templates/MainPage";
import { setPaymentIntent } from "../../hooks/payment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

function Payment() {
	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	const [clientSecret, setClientSecret] = React.useState("");
	const [amount, setAmount] = React.useState("");
	const [quotesToPay, setQuotesToPay] = React.useState([]);
	const { dispatchChat } = React.useContext(ChatContext);

	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);

	async function PaymentIntentStripe() {
		try {
			const res = await setPaymentIntent(
				auth.user?.uid,
				projects.project?.uid,
				auth?.token
			);
			const quotes = {
				tasks: res.tasks,
				randomId: res.randomId,
			};
			changeLoadingBackdrop(false);
			setClientSecret(res.clientSecret);
			setAmount(res.amount);
			setQuotesToPay(quotes);
		} catch (e) {
			changeLoadingBackdrop(false);
			console.error(e);
		}
	}

	React.useEffect(() => {
		changeLoadingBackdrop(true);
		dispatchChat({ type: "HIDE_CHAT" });
		PaymentIntentStripe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const appearance = {
		theme: "stripe",
	};
	const options = {
		clientSecret,
		appearance,
		locale: "en",
	};

	return (
		<MainPage
			header={true}
			title="Payment"
			subtitle="Secure payment"
			show={true}
			backFunction={4}
		>
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm amount={amount} quotes={quotesToPay} />
				</Elements>
			)}
		</MainPage>
	);
}

export { Payment };
