import React from "react";

import { PlatformsStep } from "../components/StepsContent/platforms";
// import { NdaStep } from "../components/StepsContent/Nda";

export const StepperContext = React.createContext();
export const StepperContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		activeStep: 0,
		skipped: new Set(),
		steps: [
			{
				text: "Platforms",
				url: "/start",
				component: <PlatformsStep />,
				step: 0,
				id: "pla0",
			},
			{
				text: "Registration",
				url: "/signup",
				step: 1,
				id: "reg1",
			},
			{
				text: "Non Disclousure Agreement",
				url: "/nda",
				// component: <NdaStep />,
				step: 2,
				id: "non2",
			},
			{
				text: "Work description",
				url: "/RequirementsDescription",
				step: 3,
				id: "tas3",
			},
			{
				text: "Connecting you with an associate",
				url: "/quote",
				step: 4,
				id: "con4",
			},
			{
				text: "Quote",
				url: "/quote",
				step: 5,
				id: "quo5",
			},
			{
				text: "Start the work",
				url: "/schedule",
				step: 6,
				id: "sta7",
			},
			{
				text: "Meet the team",
				url: "/meetTheTeam",
				step: 7,
				id: "mee8",
			},
			{
				text: "Work in progress",
				url: "/workInProgress",
				step: 8,
				id: "wor9",
			},
			{
				text: "Review the work",
				url: "/reviewTheWork",
				step: 9,
				id: "rev10",
			},
		],
		completed: [
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
		],
		selectedPlatforms: [],
		hidden: false,
		tempSteps: [],
		start: true,
	};
	const stepperReducer = (state, action) => {
		switch (action.type) {
		case "LOAD_DB_STEPS":
			return {
				...state,
				activeStep: action.payload?.DBactiveStep,
				completed: action.payload?.completed,
				selectedPlatforms: action?.payload.selectedPlatforms,
			};
		case "SET_STEPS": {
			const tempArray = [];
			for (let i = 0; i <= action.payload.activeStep + 1; i++) {
				tempArray.push(state.steps[i]);
			}
			if (state.steps.length < action.payload.activeStep + 1) {
				tempArray.push(state.steps[action.payload.activeStep + 1]);
			}
			return {
				...state,
				tempSteps: tempArray,
				activeStep: action.payload.activeStep,
			};
		}
		case "RESET_STEPPER":
			return {
				activeStep: INITIAL_STATE.activeStep,
				skipped: INITIAL_STATE.skipped,
				tempSteps: INITIAL_STATE.tempSteps,
				completed: INITIAL_STATE.completed,
			};
		case "SET_COMPLETED_STEP":
			return {
				...state,
				completedSteps: action.payload,
			};
		case "SET_NEXT_STEP":
			return {
				...state,
				activeStep: action.payload,
			};
		case "SET_ACTIVE_STEP":
			return {
				...state,
				activeStep: action.payload,
			};
		case "SET_PLATFORMS":
			return {
				...state,
				selectedPlatforms: action.tempPlatforms,
			};
		case "SET_LAST_STEP":
			return {
				...state,
				tempSteps: state.steps,
				activeStep: state.steps.length - 1,
			};
		case "HIDE_STEPPER":
			return {
				...state,
				hidden: action.payload,
			};
		case "SET_START":
			return {
				...state,
				start: action.payload,
			};
		case "SET_COMPLETED":
			return {
				...state,
				completed: action.LocalStorageCompleted,
			};
		default:
			return state;
		}
	};
	const [state, dispatchStepper] = React.useReducer(
		stepperReducer,
		INITIAL_STATE
	);
	return (
		<StepperContext.Provider value={{ dataStepper: state, dispatchStepper }}>
			{children}
		</StepperContext.Provider>
	);
};
