import LogoGoogle from "../../Sources/Icons/LogoGoogle.svg";
import { Avatar, Button, InputAdornment } from "@mui/material";

// import auth from "../../firebase";

import { /* Logout, */ loginWithGoogle } from "../../hooks/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	// createProject,
	// getProjectById,
	getProjectsByUserId,
} from "../../hooks/projects";
import {
	changeNewProjectsFallback,
	setActiveProjectById,
	// setActiveProjectId,
	// setProject,
	setProjects,
} from "../../features/project/projectSlice";
import { setUser, setToken } from "../../features/auth/authSlice";
/* import { enqueueSnackbar } from "notistack"  */
/* import { deleteUser } from "firebase/auth"; */

export default function LoginGoogleButton() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const projects = useSelector((state) => state.projectsRedux);
	async function googleLogin() {
		try {
			const { result, firebaseUser } = await loginWithGoogle();
			console.log(
				"🚀 ~ file: googleButtonLogin.js:33 ~ googleLogin ~ result:",
				result
			);
			if (result === null && firebaseUser === null) {
				return;
			}
			console.log("passed anyway - googleButtonLogin.js");
			if (result.created) {
				dispatch(setToken(firebaseUser.accessToken));
				dispatch(setUser(result));
				navigate("/signupGoogle");
			}
			// if user already exists, fetch user and projects then set in redux store, then navigate to nda page
			// if user dont have project, create project and set user and project in redux store, then navigate to nda page
			else {
				const projects = await getProjectsByUserId(
					result.uid,
					firebaseUser.accessToken
				);
				dispatch(setToken(firebaseUser.accessToken));
				dispatch(setUser(result));
				if (projects.length === 0) {
					// create project
					dispatch(changeNewProjectsFallback(true));
					// const platforms = JSON.parse(localStorage.getItem("platforms"));
					// const data = {
					// 	clientId: res.user.uid,
					// 	clientName: res.user.displayName,
					// 	platforms,
					// 	activeStep: 2,
					// 	completedSteps: [
					// 		true,
					// 		true,
					// 		false,
					// 		false,
					// 		false,
					// 		false,
					// 		false,
					// 		false,
					// 		false,
					// 		false,
					// 	],
					// 	lastCompletedStep: 1,
					// };
					// const createdProject = await createProject(data);
					// const project = await getProjectById(createdProject.uid);
					// dispatch(setProject(project));
					// dispatch(setActiveProjectId(project.uid));
					// dispatch(setProjects([project]));
					// dispatch(setUser(res.user));
					// navigate("/nda");
					return;
				}
				// set user and projects in redux store
				dispatch(setProjects(projects));
				dispatch(setActiveProjectById(result.activeProject));
				//TODO[] change navigate to "complete step"
			}
		} catch (error) {
			console.error(error);
		}
	}
	return (
		<Button
			variant="outlined"
			onClick={googleLogin}
			fullWidth
			startIcon={
				<InputAdornment position="start">
					<Avatar variant="iconAvatar" src={LogoGoogle} />
				</InputAdornment>
			}
		>
			Login with google
		</Button>
	);
}
