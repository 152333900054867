import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getDownloadURL, getMetadata, getStorage, ref } from "firebase/storage";

/**
 * Determines if the current device is a mobile device.
 * @returns {boolean} True if the device is a mobile device, false otherwise.
 */
export function isMobile() {
	const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));
	return isMobile;
}

export async function getUrlByRef(reference) {
	const storage = getStorage();
	const storageRef = ref(storage, reference);
	const link = await getDownloadURL(storageRef);
	return link;
}

export async function getMetadataByRef(reference) {
	const storage = getStorage();
	const storageRef = ref(storage, reference);
	const metadata = await getMetadata(storageRef);
	return metadata;
}

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export const steps = [
	{
		text: "Register",
		step: 0,
		url: "/newProject",
		id: "hiddenRequest",
		hidden: true,
		disabled: true,
	},
	{
		text: "Register",
		step: 1,
		url: "/signUpGoogle",
		id: "reg1.5",
		hidden: true,
		disabled: true,
	},
	{
		text: "Non Disclousure Agreement",
		url: "/nda",
		step: 2,
		id: "non2",
		hidden: true,
	},
	{
		text: "Work description",
		url: "/RequirementsDescription",
		step: 3,
		id: "pla0",
	},
	{
		text: "Quote",
		url: "/quote",
		step: 4,
		id: "quo5",
	},
	{
		text: "Checkout",
		url: "/checkout",
		step: 5,
		id: "quo5.1",
		hidden: true,
	},
	{
		text: "invoice",
		url: "/invoice",
		step: 6,
		id: "quo5.2",
		hidden: true,
	},
	{
		text: "Start the work",
		url: "/schedule",
		step: 7,
		id: "sta7",
		hidden: true,
	},
	{
		text: "Meet the team",
		url: "/meetTheTeam",
		step: 8,
		id: "mee8",
	},
	{
		text: "Work in progress",
		url: "/workInProgress",
		step: 9,
		id: "wor9",
	},
	{
		text: "Review the work",
		url: "/reviewTheWork",
		step: 10,
		id: "rev10",
	},
];

/* export const steps = [
	{
		text: "Platforms",
		url: "/start",
		step: 0,
		id: "pla0",
	},
	{
		text: "Registration",
		url: "/signup",
		step: 1,
		id: "reg1",
		disabled: true,
	},
	{
		text: "Register",
		step: 2,
		url: "/signUpGoogle",
		id: "reg1.5",
		hidden: true,
		disabled: true,
	},
	{
		text: "Non Disclousure Agreement",
		url: "/nda",
		step: 3,
		id: "non2",
	},
	{
		text: "Work description",
		url: "/RequirementsDescription",
		step: 4,
		id: "tas3",
	},
	{
		text: "Connecting you with an associate",
		url: "/RequirementsDescription",
		step: 5,
		id: "con4",
		disabled: true,
	},
	{
		text: "Quote",
		url: "/quote",
		step: 6,
		id: "quo5",
	},
	{
		text: "Checkout",
		url: "/checkout",
		step: 7,
		id: "quo5.1",
		hidden: true,
	},
	{
		text: "invoice",
		url: "/invoice",
		step: 8,
		id: "quo5.2",
		hidden: true,
	},
	{
		text: "Start the work",
		url: "/schedule",
		step: 9,
		id: "sta7",
		hidden: true,
	},
	{
		text: "Meet the team",
		url: "/meetTheTeam",
		step: 10,
		id: "mee8",
	},
	{
		text: "Work in progress",
		url: "/workInProgress",
		step: 11,
		id: "wor9",
	},
	{
		text: "Review the work",
		url: "/reviewTheWork",
		step: 12,
		id: "rev10",
	},
]; */
