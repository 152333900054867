
function Question({ question, handleInput }) {
	function generarOpciones(options) {
		return (
			<div className="options-box">
				{
					// HACER QUE SEAN RADIOS NO BOTONES, APARTE UN BOTON DE SIGUIENTE
					options.map((option, index) => (
						<>
							<input
								key={index}
								onClick={() => {
									handleInput(option["next"]);
								}}
								id={option["opcion"]}
								value={option["opcion"]}
								className="radio"
								type="radio"
								name="opciones"
							/>
							<label className="label-radio" htmlFor={option["opcion"]}>
								{option["opcion"]}
							</label>

							{/* { 
                                index == 0 ? 
                                <input onClick={e =>{handleInput(option["question-next"])}} id={option["opcion"]} value={option["opcion"]} className="radio" type="radio" name="opciones"/>: 
                                <input checked onClick={e =>{handleInput(option["question-next"])}} id={option["opcion"]} value={option["opcion"]} className="radio" type="radio" name="opciones"/>
                            } */}
							{/* <button key={option["question"]} value={option["question-next"]} className="btn btn-opcion">{option["opcion"]}</button> */}
						</>
					))
				}
			</div>
		);
	}

	return (
		<div className="questions-container fadeIn">
			<p className="pregunta">{question["question"]}</p>
			{question["options"] ? (
				generarOpciones(question["options"])
			) : (
				<input
					onClick={() => {
						handleInput(question["next"]);
					}}
					name="respuestaAbierta"
					className="input-question"
					type="text"
					placeholder="Write your awnser"
				/>
			)}
		</div>
	);
}

export { Question };
