// import React from "react";

import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import {recoverPassword} from "../../hooks/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function ResetPassword({ open, setOpen }) {
	const maxWidth = "sm";

	const { register, handleSubmit, reset } = useForm();
	const { navigate } = useNavigate();

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit = async (data) => {
		try {
			await recoverPassword(data.email);
			reset();
			setOpen(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Dialog
			maxWidth={maxWidth}
			fullWidth
			sx={{ minWidth: "50px" }}
			onClose={handleClose}
			open={open}
		>
			<DialogTitle>Recover password</DialogTitle>
			<Divider />
			<IconButton
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
				onClick={handleClose}
			>
				<CloseIcon />
			</IconButton>
			<Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
				<DialogContent sx={{ display: "flex", flexDirection: "column" }}>
					<DialogContentText gutterBottom>
						Enter your email to recover password.
					</DialogContentText>
					<TextField
						{...register("email", { required: true })}
						type="email"
						variant="outlined"
						placeholder="Email"
						fullWidth
					/>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						onClick={() => navigate("/login")}
						sx={{ marginTop: 1, alignSelf: "flex-end" }}
					>
						Send email
					</Button>
				</DialogContent>
			</Box>
		</Dialog>
	);
}
