import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import { DesktopHeader } from "./headersMainPage/DesktopHeader";
import { MobileHeader } from "./headersMainPage/MobileHeader";
import {
	setActiveStep,
	updateProject,
} from "../../features/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../hooks/utils";

import PropTypes from "prop-types";

/**
 * @component
 * @example
 * return (
 * <MainPage
 * 	title="Title"
 * 	subtitle="Subtitle"
 * 	show={true}
 * 	width="50%"
 * 	avatar="Avatar"
 * 	avatarImg="AvatarImg"
 * 	header={true}
 * 	widthPlatform="50%"
 * 	backFunction={handleBack}
 * 	backNavigate="/"
 * >
 * 	<></>
 * </MainPage>
 * )
 *
 */
export function MainPage({
	title,
	subtitle,
	children,
	show,
	width,
	avatar,
	avatarImg,
	header,
	/* widthPlatform, */
	backFunction,
	backNavigate,
}) {
	const check = isMobile();

	const theme = useTheme();
	const dispatch = useDispatch();
	// const { handleBack } = useProjects();

	const navigate = useNavigate();

	const projectStore = useSelector((state) => state.projectsRedux);

	function handleBack() {
		if (Object.keys(projectStore?.project)?.length > 0) {
			dispatch(
				setActiveStep(backFunction ?? projectStore.project?.activeStep - 1)
			);
			dispatch(updateProject());
		} else {
			navigate(backNavigate ?? -1);
		}
	}

	return (
		<Paper
			variant="pagePaper"
			sx={{
				[theme.breakpoints.down("md")]: {
					// background: `linear-gradient(180deg, ${theme.palette.backgroundGradient.first} 0%, ${theme.palette.backgroundGradient.second}) 100%`,
					paddingTop: 0,
				},
				[theme.breakpoints.between("md", "lg")]: {
					width: "100%",
				},
				[theme.breakpoints.down("xl")]: {
					paddingTop: "5.6%",
				},
				[theme.breakpoints.up("xl")]: {
					paddingTop: "5.6%",
				},
			}}
		>
			<Paper
				variant="innerContent"
				sx={{

					[theme.breakpoints.down("lg")]: {
						width: width ? width : "80%",
					},
					[theme.breakpoints.up("lg")]: {
						width: width ? width : "80%",
					},
					[theme.breakpoints.between("xl", "xxl")]: {
						width: width ? width : "50%",
					},
					[theme.breakpoints.up("xxl")]: {
						width: width ? width : "30%",
					},
					[theme.breakpoints.down("md")]: {
						width: "80%",
					},
				}}
				className="work-inprogress-container"
			>
				{header && check ? (
					<MobileHeader
						show={show}
						title={title}
						avatar={avatar}
						avatarImg={avatarImg}
						subtitle={subtitle}
						handleBack={handleBack}
					/>
				) : (
					<DesktopHeader
						show={show}
						title={title}
						avatar={avatar}
						avatarImg={avatarImg}
						subtitle={subtitle}
						handleBack={handleBack}
					/>
				)}
				{children}
			</Paper>
		</Paper>
	);
}

MainPage.propTypes = {
	/**
	 * Title of the page
	 * @type {string}
	 * @example
	 * "Title"
	 * */
	title: PropTypes.string,
	/**
	 * Subtitle of the page
	 * @type {string}
	 * @example
	 * "Subtitle"
	 * */
	subtitle: PropTypes.string,
	/**
	 * Show the title and subtitle
	 * @type {boolean}
	 * @example
	 * true
	 * */
	show: PropTypes.bool,
	/**
	 * Width of the page
	 * @type {string}
	 * @example
	 * "50%"
	 * */
	width: PropTypes.string,
	/**
	 * Avatar of the page
	 * @type {string}
	 * @example
	 * "Avatar"
	 * */
	avatar: PropTypes.string,
	/**
	 * Avatar image of the page
	 * @type {string}
	 * @example
	 * "AvatarImg"
	 *
	 */
	avatarImg: PropTypes.string,
	/**
	 * Show the header
	 * @type {boolean}
	 * @example
	 * true
	 * */
	header: PropTypes.bool,
	/**
	 * Width of the platform
	 * @type {string}
	 * @example
	 * "50%"
	 * */
	widthPlatform: PropTypes.string,
	/**
	 * Function to handle the back button
	 * @type {function}
	 * @example
	 * handleBack
	 * */
	backFunction: PropTypes.func,
	/**
	 * Navigate to the page
	 * @type {string}
	 * @example
	 * "/"
	 * */
	backNavigate: PropTypes.string,
	/**
	 *
	 * @type {reactNode}
	 * @example
	 * <></>
	 */
	children: PropTypes.node.isRequired,
};
