import React from "react";

export const BackdropContext = React.createContext();
export const BackdropContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		loadingBackdrop: false,
	};
	const backdropReducer = (state, action) => {
		switch (action.type) {
		case "CHANGE_LOADING":
			return {
				loadingBackdrop: action.payload,
			};
		default:
			return state;
		}
	};

	function changeLoadingBackdrop(loading) {
		dispatchBackdrop({
			type: "CHANGE_LOADING",
			payload: loading,
		});
	}

	const [state, dispatchBackdrop] = React.useReducer(
		backdropReducer,
		INITIAL_STATE
	);
	return (
		<BackdropContext.Provider
			value={{ backdropData: state, changeLoadingBackdrop }}
		>
			{children}
		</BackdropContext.Provider>
	);
};
