import { enqueueSnackbar } from "notistack";
import {
	getAuthed,
	getByIdAuthed,
	postMethodAuthed,
} from "../backend/services";
// import { useSelector } from "react-redux";
// const project = useSelector((state) => state.reduxProjects);

/**
 * This function gets all the projects of a client by its id

 * @param {string} uid - The id of the user
 * @returns {Promise<Array>} Projects - Returns an array of the projects of the client
 */
async function getProjectsByUserId(uid, accessToken) {
	try {
		// getClientProjects endpoint is used to get all the projects of a client
		// it receives 1 parametre: uid and its required

		const res = await getByIdAuthed({
			route: "project/getClientProjects",
			id: uid,
			accessToken,
		});
		// it returns a object with the properties 'success' and 'data'
		// 'success' is a boolean that indicates if the request was successful or not
		if (!res.success && !res.data) {
			return [];
		}
		if (!res.success) {
			throw new Error(res.message);
		}
		// 'data' is an array of projects
		return res.data;
	} catch (e) {
		console.error("Error =>", e);
	}
}

async function getProjectById(id, accessToken) {
	try {
		// getProjectById endpoint is used to get a project by its id
		// it receives 1 parametre: id and its required
		const res = await getByIdAuthed({
			route: "project/getProjectById",
			id: id,
			accessToken,
		});
		// it returns an object with the properties 'success' and 'data'
		// 'success' is a boolean that indicates if the request was successful or not
		if (!res.success || !res.data) {
			throw new Error("Error getting project");
		}
		// 'data' is the project object
		return res.data;
	} catch (e) {
		console.error("Error =>", e);
		throw e;
	}
}
/**
 * This function is used to create a new project

 * @param {object} data - The data of the project, it must contain the properties projectName, clientId, clientname, platforms, activeStep, completedSteps and lastCompletedStep
 * @return {Promise<object>} Returns an object with the response of the request
 * @throws {Error} - Throws an error if the request fails
 */
async function createProject(data, accessToken) {
	try {
		// createNewProject endpoint is used to create a new project
		// it receives 3 parametres: projectName clientId platforms
		// clientId and platforms are requiered
		// projectName is optional
		const response = await postMethodAuthed({
			route: "project/createNewProject",
			body: data,
			accessToken,
		});

		// it returns an object with the properties 'success' and 'data'
		if (!response || !response.success) {
			// if the request failed, throw an error
			throw new Error("Error creating project");
		}

		// if the request was successful, return the data
		// data is the body of the call to the endpoint (projectName clientId platforms) and
		// the id of the project created
		return response.data;
	} catch (error) {
		console.error(`Error creating project with ${data}: ${error}`);
		throw new Error("Failed to create project");
	}

	// Refactorized by OpenAi GPT-3:
	// Here are the changes made:
	// Changed the error handling to provide more information about the error.
	// If an error occurs while calling the postMethod, the function now throws
	// an error with the message "Failed to create project". The error message now
	// includes the error message from the original error.
}

/**
 * This function updates the signed agreement of a client
 *
 * @function saveSignedAgreement

 * @param {Object} data - The data of the signed agreement, including the client id, the project id, the content agreement, the image url of the signed agreement and the full name of the client
 * @returns {Promise<Object>} Success - Returns a object with the property 'success' that indicates if the request was successful or not
 * @throws Will throw an error if the request fails
 */

async function saveSignedAgreement(data, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "ticket/saveAgreementTicket",
			body: data,
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch {
		throw new Error("Failed to save signed agreement");
	}
}
/**
 * This function updates the project with the new requests data
 *
 * @function updateProject

 * @param {Object} data - The data of the project, including the requests
 * @returns {Promise<Object>} Project - Returns the response of the request, which is an object with the properties 'success' and 'requests'
 */
async function createRequests(data, accessToken) {
	const res = await postMethodAuthed({
		route: "requests/saveRequests",
		body: data,
		accessToken,
	});

	if (!res.success) {
		throw new Error(res.message);
	}
	return res.requests;
}
/**
 * This function gets all the requests of a project by its id
 *
 * @function getRequests

 * @param {string} uid - The id of the project
 * @returns {Promise<Array>} Requests - Returns an array of the requests of the project
 */
async function getRequests(uid, accessToken) {
	const res = await getByIdAuthed({
		route: "requests/getRequests",
		id: uid,
		accessToken,
	});
	if (!res.success) {
		throw new Error(res.message);
	}
	return res.data;
}
// async function updateProject(data) {}

// async function updateProject() {
// 	console.log(project);
// }

/**
 * 	This function posts the quotes to the database
 *  @function postQuotes
 *  @param {Object} data The data to post, ussually the project id from redux and the quotes, wich is an array of objects
 *  @returns {Promise<Object>} The response from the server, wich is an object with the properties 'success', 'data' and 'message'
 *  @throws Will throw an error if the request fails
 */
async function postQuotes(data, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "quote/setQuotes",
			body: { quotes: data.quotes, projectId: data.uid },
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error("Error =>", error);
		throw new Error("Failed to post quotes");
	}
}

async function sendAnswersApi(data, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/saveAnswers",
			body: {
				answers: data.answers,
				projectUid: data.uid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error("Error =>", error);
		throw new Error("Failed to send answers");
	}
}
/**
 * Approves a task by updating its status to "approved" in the database.
 * @param {string} ticketUuid - The ID of the ticket to approve.
 * @param {string} projectUid - The ID of the project where the ticket is located.
 * @returns {Promise<void>} A Promise that resolves when the task has been approved.
 */
async function approveTask(projectUid, ticketUuid, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "ticket/approveTicket",
			body: {
				projectUid,
				ticketUuid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error(error);
	}
}

/**
 * Approves a task by updating its status to "rejected" in the database.
 * @param {string} ticketUuid - The ID of the ticket to rejected.
 * @param {string} projectUid - The ID of the project where the ticket is located.
 * @returns {Promise<void>} A Promise that resolves when the task has been rejected.
 */
async function rejectTask(projectUid, ticketUuid, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "ticket/rejectTicket",
			body: {
				projectUid,
				ticketUuid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error(error);
	}
}

async function saveReview(uid, review, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/saveReview",
			body: {
				projectUid: uid,
				review,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error(error);
	}
}

async function getSurveys(accessToken) {
	if (!accessToken) {
		throw new Error("No access token provided");
	}
	try {
		const res = await getAuthed({
			route: "project/getSurveys",
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.data;
	} catch (error) {
		console.error("Error =>", error);
	}
}

async function updateProjectName(projectUid, projectName, accessToken) {
	const res = await postMethodAuthed({
		route: "project/updateProjectName",
		body: {
			projectUid,
			projectName,
		},
		accessToken,
	});
	if (!res.success) {
		throw new Error(res.message);
	}
	return res.success;
}

function setReopenModalValueToFalse(projectUid, ticketUuid, accessToken) {
	try {
		const res = postMethodAuthed({
			route: "ticket/setReopenModalValueToFalse",
			body: {
				projectUid,
				ticketUuid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error(error);
	}
}

async function sendQuestionnarieAnswer(
	projectUid,
	question,
	answer,
	id,
	accessToken
) {
	try {
		const res = await postMethodAuthed({
			route: "project/saveAnswer",
			body: {
				projectUid,
				question,
				answer,
				id,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error(error);
	}
}

async function editQuoteReady(projectUid, quoteReady, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/editQuoteReady",
			body: {
				projectUid,
				quoteReady,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		enqueueSnackbar(error.message, {
			variant: "error",
		});
		return false;
	}
}

async function ChangeNdaRequirement(projectUid, requireNda, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/changeNDARequirement",
			body: {
				projectUid,
				requireNda,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		enqueueSnackbar(error.message, {
			variant: "error",
		});
		return false;
	}
}

export {
	getProjectsByUserId,
	createProject,
	getProjectById,
	saveSignedAgreement,
	createRequests,
	getRequests,
	postQuotes,
	sendAnswersApi,
	approveTask,
	rejectTask,
	saveReview,
	getSurveys,
	updateProjectName,
	setReopenModalValueToFalse,
	sendQuestionnarieAnswer,
	editQuoteReady,
	ChangeNdaRequirement,
};
