import React from "react";
import { PlatformsStep } from "../components/StepsContent/platforms";

export const ProjectsContext = React.createContext();
export const ProjectsContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		clientId: "",
		projectId: "", //! This is the "Active Proyect"
		projects: [], //! This will be the list of proyects, read from the localStorage
		steps: [
			{
				text: "Platforms",
				url: "/start",
				component: <PlatformsStep />,
				step: 0,
				id: "pla0",
			},
			{
				text: "Registration",
				url: "/signup",
				step: 1,
				id: "reg1",
			},
			{
				text: "Non Disclousure Agreement",
				url: "/nda",
				// component: <NdaStep />,
				step: 2,
				id: "non2",
			},
			{
				text: "Work description",
				url: "/RequirementsDescription",
				step: 3,
				id: "tas3",
			},
			{
				text: "Connecting you with an associate",
				url: "/RequirementsDescription",
				step: 4,
				id: "con4",
			},
			{
				text: "Quote",
				url: "/quote",
				step: 5,
				id: "quo5",
			},
			{
				text: "Start the work",
				url: "/schedule",
				step: 6,
				id: "sta7",
			},
			{
				text: "Meet the team",
				url: "/meetTheTeam",
				step: 7,
				id: "mee8",
			},
			{
				text: "Work in progress",
				url: "/workInProgress",
				step: 8,
				id: "wor9",
			},
			{
				text: "Review the work",
				url: "/reviewTheWork",
				step: 9,
				id: "rev10",
			},
		],
		hidden: false,
		hiddenAccordions: false,
	};
	const projectsReducer = (state, action) => {
		switch (action.type) {
		case "SET_PROJECT_ID":
			return {
				...state,
				projectId: action.payload,
			};
		case "SET_PROJECTS": {
			const newtreatedSteps = [];
			const treatedSteps = [...action.payload];
			treatedSteps.forEach((step) => {
				const tempArray = [];
				for (let i = 0; i <= step.activeStep + 1; i++) {
					tempArray.push(state.steps[i]);
				}
				if (state.steps.length < step.activeStep + 1) {
					tempArray.push(state.steps[step.activeStep + 1]);
				}
				step.tempSteps = tempArray;
				newtreatedSteps.push(step);
			});
			return {
				...state,
				projects: newtreatedSteps,
				clientId: action.payload[0].clientId,
				projectId: action.payload[0].projectId,
			};
		}
		case "ADD_PROJECT":
			return {
				...state,
				projects: [...state.projects, action.payload],
			};
			//unused vvv
		case "SET_STEPS":
			return {
				...state,
				projects: action.payload,
			};
		case "SET_ACTIVE_STEP":
			return {
				...state,
				projects: action.payload,
			};
		case "RESTART_PROJECTS":
			return {
				...INITIAL_STATE,
			};
		case "HIDE_STEPPER":
			return {
				...state,
				hiddenAccordions: true,
			};
		case "SHOW_STEPPER":
			return {
				...state,
				hiddenAccordions: false,
			};
		default:
			return state;
		}
	};
	const [state, dispatchProjects] = React.useReducer(
		projectsReducer,
		INITIAL_STATE
	);
	return (
		<ProjectsContext.Provider value={{ projectsData: state, dispatchProjects }}>
			{children}
		</ProjectsContext.Provider>
	);
};
