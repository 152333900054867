import { Avatar, IconButton, Stack, Typography } from "@mui/material";

import ArrowBack from "../../../Sources/Icons/arrowBack.svg";

import useTheme from "@mui/material/styles/useTheme";

export function DesktopHeader({
	show,
	title,
	avatar,
	avatarImg,
	subtitle,
	handleBack,
}) {
	const theme = useTheme();

	return (
		<Stack>
			<Stack
				direction="row"
				sx={{
					display: "flex",
					gridTemplateColumns: "29px 1fr",
				}}
			>
				<IconButton
					disableRipple
					sx={{
						position: "relative",
						left: "-3vw",
						alignSelf: "flex-start",
						visibility: show ? "visible" : "hidden",
					}}
					onClick={handleBack}
				>
					<Avatar variant="backArrowIcon" src={ArrowBack} />
				</IconButton>
				<Typography
					variant="titles"
					sx={{
						position: "relative",
						marginBottom: "0.5rem",
						left: "-38px",
						justifySelf: "center",
					}}
				>
					{title}
				</Typography>
			</Stack>
			{avatar ? (
				<Stack
					alignItems="center"
					direction="row"
					sx={{
						marginBottom: "45px",
					}}
				>
					<Typography variant="descriptiveText">{subtitle} &nbsp;</Typography>
					<Avatar
						variant="WipAvatar"
						src={avatarImg}
						// Refactor this to make it dynamic using avatarImg prop ^
						alt="team member"
					/>
				</Stack>
			) : (
				<>
					{subtitle && (
						<Typography
							variant="descriptiveText"
							sx={{
								[theme.breakpoints.down("xl")]: {
									marginBottom: "4.5rem",
								},
								[theme.breakpoints.up("xl")]: {
									marginBottom: "4rem",
								},
							}}
						>
							{subtitle}
						</Typography>
					)}
				</>
			)}
		</Stack>
	);
}
