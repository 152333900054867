import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

const initialState = {
	tasks: [],
	nda: false,
};

export const requestSlice = createSlice({
	name: "Requests",
	initialState,
	reducers: {
		addTask: (state) => {
			const tratedTasks = {
				uuid: uuid(),
				description: "",
				attachments: [],
				createdAt: new Date().toISOString(),
				sended: false,
				deleted: false,
			};
			state.tasks = [...state.tasks, tratedTasks];
		},
		removeTask: (state, action) => {
			const tasks = [...state.tasks];
			const newTasks = tasks.filter((task) => task.uuid !== action.payload);
			state.tasks = newTasks;
		},
		descriptionChange: (state, action) => {
			const { uuid, description } = action.payload;
			const tasks = [...state.tasks];
			tasks.map((task) => {
				if (task.uuid === uuid) {
					if (task.description.length === 0) {
						task.createdAt = new Date().toISOString();
					}
					task.description = description;
				}
				return task;
			});
			state.tasks = tasks;
		},
		// add files after login, the files before loggin in are stored in context
		addFile: (state, action) => {
			const { files } = action.payload;

			const tasks = [...state.tasks];
			files.forEach((file) => {
				tasks.forEach((task) => {
					if (task.uuid === file.taskUuid) {
						task.attachments.forEach((f) => {
							if (f.id === file.id) {
								f.fileUrl = file.url;
								f.fileRef = file.ref;
							}
						});
					}
				});
			});

			state.tasks = tasks;
		},
		addFileRef: (state, action) => {
			const { uuid, object } = action.payload;
			const tasks = [...state.tasks];
			tasks.map((task) => {
				if (task.uuid === uuid) {
					task.attachments = [...task.attachments, ...object];
				}
				return task;
			});
			state.tasks = tasks;
		},
		removeFileRef: (state, action) => {
			const { file, uuid } = action.payload;
			const tasks = [...state.tasks];
			tasks.map((task) => {
				if (task.uuid === uuid) {
					task.attachments = task.attachments.filter((f) => f.id !== file.id);
				}
				return task;
			});
			state.tasks = tasks;
		},
		changeNda: (state, action) => {
			state.nda = action.payload;
		},
		removeNonUploadedFiles: (state) => {
			state.tasks.forEach((task) => {
				task.attachments = task.attachments.filter((f) => f.fileUrl !== "");
			});
		},
		purgeRequests: (state) => {
			state.tasks = [];
			state.nda = false;
		},
	},
});

export const {
	addTask,
	removeTask,
	descriptionChange,
	addFile,
	addFileRef,
	removeFileRef,
	changeNda,
	removeNonUploadedFiles,
	purgeRequests,
} = requestSlice.actions;

export default requestSlice.reducer;
