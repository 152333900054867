import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "../features/project/projectSlice";
import userReducer from "../features/auth/authSlice";
import requestsReducer from "../features/requests/requestsSlice";
import authedRequestSlice from "../features/authedRequests/authedRequestsSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";

// const userDataPersistConfig = {
// 	key: "user",
// 	storage: storage,
// };

const requestsPersistConfig = {
	key: "requestsSlice",
	storage: storage,
};

const authedRequestsSlicePersistConfig = {
	key: "authedRequestsSlice",
	storage: storage,
};

export const store = configureStore({
	reducer: {
		projectsRedux: projectsReducer,
		auth: userReducer,
		requestsSlice: persistReducer(requestsPersistConfig, requestsReducer),
		authedRequestsSlice: persistReducer(
			authedRequestsSlicePersistConfig,
			authedRequestSlice
		),
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					"persist/PERSIST",
					"persist/PURGE",
					"persist/REHYDRATE",
				],
			},
		}),
});

export const persistor = persistStore(store);
