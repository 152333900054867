import api from "../config/apiConfig";

/**
 * This function is used to make a POST request to the backend
 * @param {string} route - The route of the endpoint
 * @param {object} body - The body of the request
 * @returns {Promise<object>} Returns an object with the response of the request
 */
async function postMethod({ route, body }) {
	// const token = await getAuth().currentUser.accessToken;
	return fetch(`${api.url}/${route}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			// "Authorization": `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}
async function postMethodAuthed({ route, body, accessToken }) {
	return fetch(`${api.url}/${route}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(body),
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

/**
 * This function is used to make a GET with id request to the backend
 * @param {string} route - The route of the endpoint
 * @param {object} body - The body of the request
 * @returns {Promise<object>} - Returns an object with the response of the request
 */
async function getById({ route, id }) {
	return fetch(`${api.url}/${route}/${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

/**
 * This function is used to make a GET with id request to the backend and no authorization
 * @param {string} route - The route of the endpoint
 * @param {string} id - The id of the object
 * @returns {Promise<object>} - Returns an object with the response of the request
 */
async function getByIdNoAuth({ route, id }) {
	return fetch(`${api.url}/${route}/${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

/**
 * This function is used to make a GET request to the backend
 * @param {string} route - The route of the endpoint
 * @returns {Promise<object>} - Returns an object with the response of the request
 */
async function get({ route }) {
	return fetch(`${api.url}/${route}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

/**
 * @typedef {Object} Options
 * @property {String} route - The route to send the GET request to.
 * @property {String} userToken - The user token to include in the authorization header.
 */

/**
 * Sends a GET request to the specified route with the user token in the authorization header.
 * @param {Object<Options>} options - The options object containing the route and user token.
 * @returns {Promise<Object>} A Promise that resolves with the response data from the GET request.
 * @throws {Error} If there is an error with the GET request.
 */
async function getAuthed({ route, accessToken }) {
	return fetch(`${api.url}/${route}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

async function getByIdAuthed({ route, id, accessToken }) {
	return fetch(`${api.url}/${route}/${id}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

export {
	postMethod,
	getById,
	getByIdNoAuth,
	get,
	getAuthed,
	getByIdAuthed /* , getAll */,
	postMethodAuthed,
};
