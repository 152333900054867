import React from "react";
import Button from "@mui/material/Button";

// import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { createProject, getProjectsByUserId } from "../../hooks/projects";
import { signInWithGoogle, updateClientDb } from "../../hooks/auth";
import {
	setProject,
	setProjects,
	setActiveProjectById,
	setActiveProjectId,
} from "../../features/project/projectSlice";
import { setToken, setUser } from "../../features/auth/authSlice";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "@mui/material/Avatar";
import LogoGoogle from "../../Sources/Icons/LogoGoogle.svg";
import { persistor } from "../../Store";
import { purgeRequests } from "../../features/requests/requestsSlice";
import { BackdropContext } from "../../Context/BackdropContext";

export default function SignUpGoogleButton() {
	const dispatch = useDispatch();
	const { nda, tasks } = useSelector((state) => state.requestsSlice);
	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	async function googleLogin() {
		try {
			changeLoadingBackdrop(true);

			const { result, firebaseUser, tokenResponse } = await signInWithGoogle();

			// if user is created create project as well and set user and project in redux store, then navigate to '/signupGoogle'
			dispatch(setToken(firebaseUser.accessToken));
			const project = {
				clientUid: result.uid,
				clientName: result.displayName,
				platforms: [],
				activeStep: 1,
				email: result.email,
				requireNda: nda,
				requests: tasks,
				completedSteps: [
					true,
					false,
					!nda,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				],
				lastCompletedStep: 0,
			};

			if (tokenResponse.isNewUser) {
				const createdProject = await createProject(
					project,
					firebaseUser.accessToken
				);
				const updatedUser = {
					...result,
					activeProject: createdProject.uid,
				};
				await updateClientDb(updatedUser, firebaseUser.accessToken);
				persistor.pause();
				dispatch(purgeRequests());
				persistor.purge();
				persistor.persist();
				dispatch(setUser(updatedUser));
				dispatch(setProject(createdProject));
				dispatch(setActiveProjectId(createdProject.uid));
				dispatch(setProjects([createdProject]));
				changeLoadingBackdrop(false);
				return;
			} else {
				const projects = await getProjectsByUserId(
					result.uid,
					firebaseUser.accessToken
				);
				if (projects.length === 0) {
					const createdProject = await createProject(
						project,
						firebaseUser.accessToken
					);
					const updatedUser = {
						...result,
						activeProject: createdProject.uid,
					};
					await updateClientDb(updatedUser, firebaseUser.accessToken);
					dispatch(setUser(updatedUser));
					persistor.pause();
					persistor.purge();
					dispatch(purgeRequests());
					persistor.persist();
					dispatch(setProject(createdProject));
					dispatch(setActiveProjectId(createdProject.uid));
					dispatch(setProjects([createdProject]));
					changeLoadingBackdrop(false);

					return;
				}
				dispatch(setActiveProjectById(result.user.activeProject));
				dispatch(setProjects(projects));
				changeLoadingBackdrop(false);
			}
		} catch (e) {
			console.error(e.message);
			changeLoadingBackdrop(false);
		}
		// if user already exists, fetch user and projects then set in redux store, then navigate to nda page
		// if user dont have project, create project and set user and project in redux store, then navigate to nda page
		/* 			else {
				const projects = await getProjectsByUserId(result.user.uid, auth.token);
				if (projects.length === 0) {
					// create project
					const platforms = JSON.parse(localStorage.getItem("platforms"));
					const data = {
						clientUid: result.user.uid,
						clientName: result.user.displayName,
						platforms,
						email: result.user.email,
						activeStep: 2,
						completedSteps: [
							true,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
						],
						lastCompletedStep: 1,
					};
					const createdProject = await createProject(data, auth.token);
					const project = await getProjectById(createdProject.uid, auth.token);
					dispatch(setProject(project));
					dispatch(setActiveProjectId(project.uid));
					dispatch(setProjects([projects]));
					dispatch(setUser(result.user));
					return;
				}
				// set user and projects in redux store
				dispatch(setProjects(projects));
				dispatch(setActiveProjectById(result.user.activeProject));
				dispatch(setUser(result.user));
			} */
	}

	return (
		<Button
			onClick={googleLogin}
			variant="signUpButton"
			fullWidth
			startIcon={
				<InputAdornment position="start">
					<Avatar variant="iconAvatar" src={LogoGoogle} />
				</InputAdornment>
			}
			sx={{
				marginBottom: 4,
			}}
		>
			Sign Up using Google
		</Button>
	);
}
