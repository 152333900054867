import React from "react";
// import { useNavigate } from "react-router-dom";
import { getByIdAuthed, postMethodAuthed } from "../backend/services";
import {
	createUserWithEmailAndPassword,
	deleteUser,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	// getIdToken,
	updateProfile,
} from "firebase/auth";

import { auth, googleProvider } from "../firebase";
import { enqueueSnackbar } from "notistack";
// import { setToken } from "../features/auth/authSlice";
// import { persistor } from "../Store";

// Create user with email and password with firebase, then api call to store it in database
async function createUser(email, pass, data) {
	try {
		// Create user with firebase
		const firebaseUser = await createUserWithEmailAndPassword(
			auth,
			email,
			pass
		);
		// Update the profile with the name and photo
		await updateProfile(firebaseUser.user, {
			displayName: `${data.firstName} ${data.lastName}`,
			photoURL:
				"https://firebasestorage.googleapis.com/v0/b/tuneup-8267c.appspot.com/o/noProfilePicture.svg?alt=media&token=1a19ab51-bb88-4928-a7f0-ef8ca1c4ab3c",
		});

		// userStorage object is the user object that will be stored in the database
		const userStorage = {
			...firebaseUser.user,
			uid: firebaseUser.user.uid,
			displayName: `${data.firstName} ${data.lastName}`,
			firstName: data.firstName,
			lastName: data.lastName,
			phone: data.phone || "",
			feedback: data.feedBack || "",
			company: data.company || "",
			photoURL:
				"https://firebasestorage.googleapis.com/v0/b/tuneup-8267c.appspot.com/o/noProfilePicture.svg?alt=media&token=1a19ab51-bb88-4928-a7f0-ef8ca1c4ab3c",
		};
		// Call API to create client in firestore
		await postMethodAuthed({
			route: "client/createClient",
			body: {
				...userStorage,
			},
			accessToken: firebaseUser.user.accessToken,
		});
		// then get the user from the database
		const user = await getClientById(
			userStorage.uid,
			firebaseUser.user.accessToken
		);
		// finally return the user
		Object.assign(user, {
			createdAt: parseInt(firebaseUser.user.metadata.createdAt / 1000),
			lastLoginAt: parseInt(firebaseUser.user.metadata.lastLoginAt / 1000),
		});
		return { user, firebaseUser };
	} catch (error) {
		throw new Error(`Failed to create user: ${error.message}`);
	}

	// Refactorized by OpenAi GPT-3 Changes:
	// - Wrapped the function body in a try-catch block to catch any errors that might occur
	// - Replaced the string concatenation for the display name with template literals
	// - Used the logical OR operator (||) instead of the nullish coalescing operator (??) for optional parameters (since the default value is a string)
	// - Removed the unnecessary variable declaration and assignment for 'user'
}

async function loginWithGoogle() {
	try {
		// First register/login with firebase
		const res = await signInWithPopup(auth, googleProvider);
		if (res._tokenResponse.isNewUser) {
			await Logout();
			await deleteUser(res.user)
				.then(() => {
					// User deleted.
					console.log("🚀 user deleted");
				})
				.catch((error) => {
					console.error("🚀 deleteUser ~ error:", error);
					// An error ocurred
					// ...
				});
			enqueueSnackbar("Please register first.", { variant: "warning" });
			return {
				result: null,
				firebaseUser: null,
				tokenResponse: null,
			};
		}
		const firebaseUser = res.user;
		const emailFallback = res._tokenResponse.email;
		const data = {
			email: firebaseUser?.email || emailFallback,
			displayName: firebaseUser.displayName,
			uid: firebaseUser.uid,
			firstName: firebaseUser.displayName.split(" ")[0],
			lastName: firebaseUser.displayName.split(" ")[1],
			photoURL: firebaseUser.photoURL,
		};
		// result is object => {created: boolean, user: object}
		const result = await createClientGoogle(data, firebaseUser.accessToken);
		console.log("🚀 ~ file: auth.js:114 ~ loginWithGoogle ~ result:", result);
		Object.assign(result.user, {
			createdAt: parseInt(firebaseUser.metadata.createdAt / 1000),
			lastLoginAt: parseInt(firebaseUser.metadata.lastLoginAt / 1000),
		});
		return {
			result: result.user,
			firebaseUser,
			tokenResponse: res._tokenResponse,
		};
	} catch (err) {
		console.error(err);
		alert("unexpected error");
	}
}

async function signInWithGoogle() {
	try {
		// First register/login with firebase
		const res = await signInWithPopup(auth, googleProvider);
		console.log("passed anyway - auth.js");
		const firebaseUser = res.user;
		const emailFallback = res._tokenResponse.email;
		const data = {
			email: firebaseUser?.email || emailFallback,
			displayName: firebaseUser.displayName,
			uid: firebaseUser.uid,
			firstName: firebaseUser.displayName.split(" ")[0],
			lastName: firebaseUser.displayName.split(" ")[1],
			photoURL: firebaseUser.photoURL,
		};
		// result is object => {created: boolean, user: object}
		const result = await createClientGoogle(data, firebaseUser.accessToken);
		Object.assign(result.user, {
			createdAt: parseInt(firebaseUser.metadata.createdAt / 1000),
			lastLoginAt: parseInt(firebaseUser.metadata.lastLoginAt / 1000),
		});
		return {
			result: result.user,
			firebaseUser,
			tokenResponse: res._tokenResponse,
		};
	} catch (err) {
		console.error(err);
		alert("unexpected error");
	}
}
async function login(email, password) {
	try {
		// first login with firebase
		const firebaseUser = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);

		// then get the user from the database, by api
		const user = await getClientById(
			firebaseUser.user.uid,
			firebaseUser.user.accessToken
		);
		/* 
            delete the createdAt property from the user object
            bc is a class object and redux cries
        */
		Object.assign(user, {
			// convert to seconds
			createdAt: parseInt(firebaseUser.user.metadata.createdAt / 1000),
			lastLoginAt: parseInt(firebaseUser.user.metadata.lastLoginAt / 1000),
		});
		return { user, firebaseUser };
	} catch (error) {
		enqueueSnackbar(error, { variant: "error" });
		throw error;
	}
}

async function Logout() {
	try {
		// first logout with firebase
		await auth.signOut();
		// then delete the user from redux storage
		// persistor.purge();
	} catch (error) {
		enqueueSnackbar(error, { variant: "error" });
		throw error;
	}
}

async function createClientGoogle(data, accessToken) {
	try {
		// Call API to create client
		// client/createClientGoogle endpoint is used to login or register a user with google
		const res = await postMethodAuthed({
			route: "client/createClientGoogle",
			body: data,
			accessToken,
		});
		// it returns an object with the properties 'created' and 'user'
		// 'created' is a boolean that indicates if the user was created or not, it is is true if the user was created, false if the user already existed
		// 'user' is the user object
		const { created, data: user } = res;
		if (!res.success) {
			throw new Error(`Could not create client: ${res.message}`);
		}
		// if the user was created you need to create project as well
		// if the user already existed you need to get their projects

		return { created, user };
	} catch (e) {
		console.error(e);
		throw e;
	}
}

async function getClientById(clientId, accessToken) {
	try {
		const { success, user } = await getByIdAuthed({
			route: "client/getClientById",
			id: clientId,
			accessToken,
		});

		if (!success) {
			throw new Error(`Client not found with ID: ${clientId}`);
		}

		delete user.createdAt;
		return user;
	} catch (error) {
		console.error(`Error fetching client with ID ${clientId}: ${error}`);
		throw error;
	}
	// Refactorized by OpenAi GPT-3:
	// In this version of the function:
	// The parameter is renamed to clientId for consistency.
	// The success and user properties are destructured from the res object.
	// An error is thrown if success is false, with a more descriptive message that includes the client ID.
	// A more descriptive error message is logged to the console, including the client ID and the original error message.
}

async function updateClientDb(user, accessToken) {
	const userNew = {
		activeChat: user.activeChat,
		displayName: user.displayName,
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		photoURL: user.photoURL,
		activeProject: user.activeProject,
		phone: user.phone,
		feedback: user.feedback,
		company: user.company,
		isNdaSigned: user.isNdaSigned,
	};

	try {
		const res = await postMethodAuthed({
			route: "client/updateClient",
			body: {
				id: user.uid,
				data: userNew,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return user;
	} catch (error) {
		console.error(`Error updating client: ${error}`);
		throw error;
	}
}

function AuthState() {
	const [currentUser, setCurrentUser] = React.useState({});
	React.useEffect(() => {
		const unSubscribe = onAuthStateChanged(auth, (user) => {
			setCurrentUser(user);
		});
		() => {
			return unSubscribe;
		};
	}, []);
	return currentUser;
}

async function getPayments(clientUid, projectId, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "payments/listPayments",
			body: {
				clientUid,
				projectId,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.data;
	} catch (error) {
		console.error(`Error getting payments: ${error}`);
		throw error;
	}
}

async function recoverPassword(email) {
	sendPasswordResetEmail(auth, email)
		.then(() => {
			enqueueSnackbar("Email sent", { variant: "success" });
		})
		.catch((error) => {
			enqueueSnackbar("Error to send email", { variant: "error" });
			console.error(error);
		});
}

export {
	createUser,
	signInWithGoogle,
	login,
	getClientById,
	updateClientDb,
	AuthState,
	Logout,
	getPayments,
	recoverPassword,
	loginWithGoogle,
};
