// import

import Avatar from "@mui/material/Avatar";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Divider, Stack } from "@mui/material";
import { isMobile } from "../../hooks/utils";

export function MainModal({
	maxWidth,
	fullScreen,
	open,
	scroll,
	title,
	content,
	actions,
	closeIcon,
	handleClose,
	textContent,
}) {
	return (
		<Dialog
			maxWidth={maxWidth}
			fullScreen={fullScreen}
			open={open}
			scroll={scroll}
			onClose={handleClose}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "#ffffffcc",
					backdropFilter: "blur(10px)",
				},
				"& .MuiDialog-container": {
					marginLeft: fullScreen ? 0 : "18%",
				},
			}}
			hideBackdrop
			closeAfterTransition={true}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "25px",
						color: "#112031",
					}}
				>
					{title}
					{closeIcon && (
						<IconButton
							disableRipple
							sx={{ width: "fit-content" }}
							onClick={handleClose}
						>
							<Avatar src={closeIcon} variant="closeIcon" />
						</IconButton>
					)}
				</DialogTitle>
			)}
			{textContent ? (
				<DialogContentText>{content}</DialogContentText>
			) : (
				<DialogContent
					sx={{
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "15px",
						color: "#285D69",
					}}
				>
					{content}
				</DialogContent>
			)}
			{actions && (
				<DialogActions
					sx={{
						justifyContent: "center",
					}}
				>
					{actions}
				</DialogActions>
			)}
		</Dialog>
	);
}
export function TermsModal({
	maxWidth,
	fullScreen,
	open,
	scroll,
	title,
	content,
	closeIcon,
	setOpen,
	loading,
}) {
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Dialog
			maxWidth={maxWidth}
			fullScreen={fullScreen}
			open={open}
			scroll={scroll}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "#ffffffcc",
					backdropFilter: "blur(10px)",
				},
				"& .MuiDialog-container": {
					marginLeft: fullScreen ? 0 : "18%",
				},
			}}
			hideBackdrop
		>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					fontFamily: "Ulagadi Sans, sans-serif",
					fontWeight: "400",
					fontSize: "25px",
					color: "#112031",
				}}
			>
				{title}
				{closeIcon && (
					<IconButton
						disableRipple
						sx={{ width: "fit-content" }}
						onClick={handleClose}
					>
						<Avatar src={closeIcon} variant="closeIcon" />
					</IconButton>
				)}
			</DialogTitle>
			<Divider />
			{!loading ? (
				<DialogContent
					dividers
					sx={{
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "15px",
						color: "#285D69",
						padding: "1% 8% 1% 8%",
					}}
					dangerouslySetInnerHTML={{ __html: content.content }}
				/>
			) : (
				<Stack sx={{ display: "grid" }}>
					<CircularProgress
						thickness={3}
						size={100}
						sx={{ placeSelf: "center" }}
					/>
				</Stack>
			)}
		</Dialog>
	);
}

export function CustomModal({ children, props }) {
	const check = isMobile();
	return (
		<Dialog
			hideBackdrop
			{...props}
			fullScreen={check}
			PaperProps={{
				elevation: 0,
			}}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "#ffffffcc",
					backdropFilter: "blur(10px)",
				},
				"& .MuiDialog-container": {
					marginLeft: check ? 0 : "18%",
				},
				...props.sx,
			}}
			closeAfterTransition={true}
		>
			{children}
		</Dialog>
	);
}
