import { get } from "../backend/services";

export async function getPlatforms() {
	const platforms = await get({
		route: "platforms/getPlatforms",
	});

	if (!platforms.success) {
		throw new Error("Error loading platforms");
	}
	return platforms.platforms;
}
