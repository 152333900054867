// react import
// third party imports

// MUI IMPORTS

import Divider from "@mui/material/Divider";

import "./Login.css";

// Functions

// components and icons
import { MainPage } from "../templates/MainPage";
import ReusableModal from "../reusableComponents/modal";
import { ModalPlatforms } from "./createProject";
// redux imports

import LoginForm from "./loginForm";
import LoginGoogleButton from "./googleButtonLogin";
import { useDispatch, useSelector } from "react-redux";

import { changeNewProjectsFallback } from "../../features/project/projectSlice";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@emotion/react";

function Login() {
	const dispatch = useDispatch();

	const projects = useSelector((state) => state.projectsRedux);

	// const theme = useTheme();

	// const extraLargeScreen = useMediaQuery(theme.breakpoints.down("xxl"));

	return (
		<MainPage
			header={true}
			show={true}
			title="Log In"
			subtitle="Log in with your work Google account or use the form"
			width="30%"
		>
			<ReusableModal
				dialogProps={{
					onClose: () => dispatch(changeNewProjectsFallback(false)),
					open: projects.newProjectFalback,
					maxWidth: "md",
					fullScreen: true,
					scroll: "paper",
					sx: {
						"& .MuiDialog-paper": {
							backgroundColor: "#ffffffcc",
							backdropFilter: "blur(10px)",
						},
					},
				}}
			>
				<ModalPlatforms />
			</ReusableModal>
			<LoginGoogleButton />
			<Divider flexItem>Or</Divider>
			<LoginForm />
		</MainPage>
	);
}

export { Login };
